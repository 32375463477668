<template>
    <CustomSelect
        v-if="hasMultipleUsers"
        :modelValue="modelValue"
        inputKey="prospects_filter_owner"
        @update:modelValue="onChanged"
        :options="ownerOptions"
        :showLabel="showLabel"
    />
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useAccountStore } from '@/stores/account';
    import { useUsersStore } from '@/stores/users';
    import CustomSelect from '@/components/elements/inputs/CustomSelect';

    export default {
        name: "CustomSelectOwner",
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            includeOptionAll: {
                type: Boolean,
                default: true
            },
            includeOptionReset: {
                type: Boolean,
                default: false
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
        },
        components: {
            CustomSelect
        },
        computed: {
            ...mapState(useAccountStore, ['account']),
            ...mapState(useUsersStore, ['users']),
            hasMultipleUsers() {
                return this.users && this.users.length > 1;
            },
            ownerOptions() {
                var owners = this.users.map(user => ({
                    noTranslate: true,
                    text: user.name ? `${user.name} (${user.email})` : user.email,
                    value: user.uid
                }));

                if(this.includeOptionAll) {
                    owners.unshift({
                        text: 'fields.prospects_filter_owner.option_all',
                        value: 'all'
                    });
                }

                if(this.includeOptionReset) {
                    owners.unshift({
                        text: 'fields.prospects_filter_owner.option_reset',
                        value: ''
                    });
                }

                return owners;
            }
        },
        methods: {
            ...mapActions(useUsersStore, ['getUsers']),
            onChanged(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
        async mounted() {
            await this.getUsers();
        }
    }
</script>