<template>
    <div v-if="popupType" @click="closePopup" class="popup-escape"></div>

    <PopupFetch
      v-if="popupType === 'fetch'"
      v-bind="popupProps"
    />
    <PopupConfirm
      v-if="popupType === 'confirm'"
      v-bind="popupProps"
    />
    <PopupNotice
      v-if="popupType === 'notice'"
      v-bind="popupProps"
    />
    <PopupForm
      v-if="popupType === 'form'"
      v-bind="popupProps"
    />
</template>

<script>
import { usePopupStore } from '@/stores/popup';
import { mapActions } from 'pinia';
import PopupFetch from '@/components/popups/PopupFetch';
import PopupConfirm from '@/components/popups/PopupConfirm';
import PopupNotice from '@/components/popups/PopupNotice';
import PopupForm from '@/components/popups/PopupForm';

export default {
    name: 'PopupContainer',
    components: {
        PopupConfirm,
        PopupNotice,
        PopupForm,
        PopupFetch
    },
    computed: {
        popupStore() {
            return usePopupStore();
        },
        popupType() {
            return this.popupStore.popupType;
        },
        popupProps() {
            return this.popupStore.popupProps;
        }
    },
    methods: {
        ...mapActions(usePopupStore, ['closePopup']),
    },
    watch: {
        $route() {
            this.closePopup();
        }
    },
};
</script>
