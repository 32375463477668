<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="true"
        :defaultToggle="defaultToggle"
        v-if="!hide"
        loadingText="components.prospect_pageviews.loading_text"
        cssClass="content-type-9"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_owner.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="owners">
                    <div class="form-type-1">
                        <CustomSelectOwner
                            :modelValue="owner"
                            @update:modelValue="onChanged"
                            :includeOptionAll="false"
                            :includeOptionReset="true"
                            :showLabel="false"
                        />
                    </div>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { mapState } from 'pinia';
    import { useProspectStore } from '@/stores/prospect';
    import { useUsersStore } from '@/stores/users';
    import CustomSelectOwner from '@/components/elements/inputs/CustomSelectOwner';
    import WidgetSection from '@/components/sections/WidgetSection';
    import i18n from "@/plugins/i18n";
    import { useToastStore } from '@/stores/toast';

    export default {
        name: "ProspectTags",
        components: {
            WidgetSection,
            CustomSelectOwner,
        },
        props: {
            defaultToggle: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState(useProspectStore, ['prospect']),
            ...mapState(useUsersStore, ['users', 'loading', 'getUsers']),
            owner() {
                return this.prospect.owner_id || '';
            },
            hasMultipleUsers() {
                return !this.loading && this.users && this.users.length > 1;
            },
            hide() {
                return !this.prospect || !this.hasMultipleUsers;
            },
        },
        methods: {
            onChanged(val) {
                const result = useProspectStore().updateOwner(val);

                if(result) {
                    useToastStore().addToast(i18n.global.t('toasts.success.owner_updated'), 'success');
                } else {
                    useToastStore().addToast(i18n.global.t('toasts.error.owner_not_updated'), 'error');
                }
            }
        },
        async mounted() {
            await this.getUsers();
        }
    }
</script>