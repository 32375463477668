<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @loading-start="handleLoadingStart"
        loadingText="common.loading_1"
        :paywall="paywall"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_property.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="section">
                    <ul class="specs">
                        <li v-if="property.details?.gebruiksdoel">
                            <div class="title">
                                {{ $t('components.prospect_property.type')}}
                            </div>
                            <div class="content capitalize">
                                {{ property.details.gebruiksdoel }}
                            </div>
                        </li>
                        <li v-if="property.details?.energielabel">
                            <div class="title">
                                {{ $t('components.prospect_property.energy_label')}}
                            </div>
                            <div class="content">
                                {{ property.details.energielabel }}
                            </div>
                        </li>
                        <li v-if="property.details?.bouwjaar">
                            <div class="title">
                                {{ $t('components.prospect_property.year_construction')}}
                            </div>
                            <div class="content">
                                {{ property.details.bouwjaar }}
                            </div>
                        </li>
                        <li v-if="property.details?.oppervlakte">
                            <div class="title">
                                {{ $t('components.prospect_property.size_floor')}}
                            </div>
                            <div class="content">
                                {{ formatNumber(property.details.oppervlakte, '', 'm²') }}
                            </div>
                        </li>
                        <li v-if="property.details?.grondoppervlakte">
                            <div class="title">
                                {{ $t('components.prospect_property.size_land')}}
                            </div>
                            <div class="content">
                                {{ formatNumber(property.details.grondoppervlakte, '', 'm²') }}
                            </div>
                        </li>
                        <li v-if="property.details?.woz">
                            <div class="title">
                                {{ $t('components.prospect_property.woz')}}
                            </div>
                            <div class="content">
                                {{ formatNumber(property.details.woz, '€', '') }} <span v-if="property.details.woz_year" class="woz-year">({{ property.details.woz_year }})</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { useAccountStore } from '@/stores/account';
    import { fetchUrl } from '@/services/ppApi';
    import WidgetSection from '@/components/sections/WidgetSection';
    import { formatNumber } from '@/utils/formatting';

    export default {
        name: "ProspectSameAddress",
        components: {
            WidgetSection
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            },
            paywall: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                loaded: false
            }
        },
        computed: {
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            },
            hide() {
                return !this.property || (!this.loading && this.loaded && (!this.property.details || !!Object.keys(this.property.details).length < 1));
            },
            property() {
                return useProspectStore().property;
            },
        },
        methods: {
            formatNumber,
            async fetchProperty() {
                if (!this.property || (this.property.api_urls && this.property.api_urls.length < 1) || this.loaded) {
                    return;
                }
                this.loading = true;

                try {
                    const response = await fetchUrl(this.property.api_urls[0]);
                    this.property.details = response.data.property;
                    this.loaded = true;
                } catch {
                    this.property?.details ? this.property.details = null : '';
                    this.loaded = true; // Potential improvement: manual retry
                } finally {
                    this.loading = false;
                }
            },
            async handleLoadingStart() {
                if (!this.paywallActive && !this.loaded) {
                    await this.fetchProperty();

                    if(!this.hide) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                }
            }
        },
        mounted() {
            if(!this.paywallActive && !this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchProperty();
            }
        }
    }
</script>