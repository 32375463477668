<template>
    <div class="steps-type-1" v-if="steps">
        <button
            v-for="(step, index) in steps"
            :key="index"
            :class="['step', step.completed ? 'completed' : '', step.current ? 'active' : 'inactive', !step.current && step.clickable ? 'clickable' : 'disabled']"
            :disabled="step.current || !step.clickable"
            @click="onClick(step)"
        >
            <div class="step-icon">
                <div class="number">{{index + 1}}</div>
            </div>
            <div class="step-name">{{step.name}}</div>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'ProcessSteps',
        emits: ['navigateToStep'],
        props: {
            steps: {
                type: Array,
                required: true
            }
        },
        methods: {
            onClick(step) {
                if(step.current || !step.clickable) { return; }
                this.$emit('navigateToStep', step.key);
            }
        }
    }
</script>