<template>
    <SettingsSection cssClass="account-details-settings">
        <template #title>
            <h2>{{ $t('views.account_subscriptions.subscription_settings_title') }}</h2>
        </template>

        <template #content>
            <SubscriptionForm :hideSaveButton="true" :hideNonSelected="true" />
            <router-link :to="{ name: 'order' }" class="btn-1 btn-block btn-m">{{$t('views.account_subscriptions.button_switch_subscriptions')}}</router-link>
        </template>
    </SettingsSection>
</template>

<script>
    import SettingsSection from '@/components/account/settings/Section';
    import SubscriptionForm from '@/components/forms/SubscriptionForm';
    
    export default {
        name: 'InvoiceSettings',
        components: {
            SettingsSection,
            SubscriptionForm
        }
    }
</script>