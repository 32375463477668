<template>
    <ToggleSimple>
        <template #toggle>
            <div class="left">
                <div v-if="$te(`fields.${name}.label`)" class="toggle-text">
                    {{ $t(`fields.${name}.label`) }}
                    <span v-if="count.selected && count.selected > 0">{{ count.selected }}</span>
                </div>
            </div>
            <div class="right">
                <div v-if="isActive" class="reset" @click="resetSection">
                    <div class="icon-reset-pos"></div>
                </div>
                <div class="toggle-icon"></div>
            </div>
        </template>

        <template #content>
            <div :class="['autosuggest-type-1', filteredSuggestions.length > 0 ? 'active' : '']">
                <CustomCheckbox
                    :modelValue="selectedValues"
                    :inputKey="name"
                    @update:modelValue="onInput"
                    :options="appsOptions"
                    :cssClass="cssClass"
                    :showLabel="false"
                    ref="optionsApps"
                    v-if="appsOptions?.length > 0"
                />
            <div class="autosuggest-input">
                    <CustomInput
                        :modelValue="search"
                        :inputKey="name"
                        @update:modelValue="onChanged"
                        @focus="onFocus"
                        :showLabel="false"
                        @keydown.down.prevent="highlightNext"
                        @keydown.up.prevent="highlightPrevious"
                        @keydown.enter.prevent="selectHighlighted"
                        ref="suggestionsSearch"
                    />

                    <div class="autosuggest-options" v-if="filteredSuggestions.length > 0" ref="suggestionsList">
                        <ul @click.stop>
                            <li 
                                v-for="(suggestion, index) in filteredSuggestions" 
                                :key="suggestion.value" 
                                :class="{ 'highlighted': index === highlightedIndex }"
                                @click="addSuggestion(suggestion)"
                            >
                                {{ suggestion.text }}
                            </li>
                        </ul> 
                    </div>
                </div>
            </div>

        </template>
    </ToggleSimple>
</template>

<script>
    import { debounce } from 'lodash';
    import { fetchSuggestions } from '@/services/ppApi';
    import { useAccountStore } from '@/stores/account';
    import { nextTick } from 'vue';
    import CustomCheckbox from '@/components/elements/inputs/CustomCheckbox';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import ToggleSimple from '@/components/elements/misc/ToggleSimple';

    export default {
        name: "OptionsSocials",
        components: {
            ToggleSimple,
            CustomCheckbox,
            CustomInput
        },
        props: {
            modelValue: {
                type: Array,
                default: () => []
            },
            cssClass: {
                type: String,
                default: 'custom-checkbox-type-5'
            }
        },
        data() {
            return {
                name: 'apps',
                search: '',
                loading: false,
                suggestions: [],
                highlightedIndex: -1,
                options: this.modelValue || []
            };
        },
        computed: {
            isActive() {
                return this.modelValue.length > 0;
            },
            appsOptions() {
                return this.options.map(option => ({
                    ...option,
                    noTranslate: true
                }));
            },
            selectedValues() {
                return this.options.map(option => option.value);
            },
            filteredSuggestions() {
                return this.suggestions.filter(suggestion => !this.options.some(option => option.value === suggestion.value));
            },
            count() {
                return {
                    total: this.options.length,
                    selected: this.options.length,
                    not_selected: this.options.length - this.options.length
                }
            }
        },
        methods: {
            resetSection(event = null) {
                if (event) {
                    event.stopPropagation()
                }
                this.options = [];
                nextTick(() => {
                    this.$emit('update:modelValue', []);
                });
            },
            onChanged: debounce(function(newValue) {
                this.search = newValue;
                this.getSuggestions(newValue);
            }, 300),
            async getSuggestions(newValue) {
                if(this.loading || newValue.length < 1) {
                    this.suggestions = [];
                    return;
                }

                this.loading = true;

                try {
                    const accountStore = useAccountStore();
                    const response = await fetchSuggestions({
                        account_id: accountStore.account.account_id,
                        type: 'apps',
                        q: newValue
                    });
                    this.suggestions = response.data.suggest.map(suggestion => ({
                        value: suggestion.term,
                        text: suggestion.name,
                        icon: suggestion.img
                    }));
                } catch {
                    this.suggestions =  [];
                } finally {
                    this.highlightedIndex = -1;
                    this.loading = false;
                }
            },
            addSuggestion(suggestion) {
                if (!this.options.some(option => option.value === suggestion.value)) {
                    this.options.push(suggestion);
                    this.setValue();
                }
            },
            handleClickOutside(event) {
                if (this.$refs.suggestionsList && !this.$refs.suggestionsList.contains(event.target)) {
                    this.suggestions = [];
                    this.highlightedIndex = -1;
                }
            },
            onInput(newValue) {
                this.options = this.options.filter(option => newValue.includes(option.value));
                this.setValue();
            },
            onFocus() {
                this.onChanged(this.search);
            },
            setValue() {
                nextTick(() => {
                    this.$emit('update:modelValue', this.options);
                });
            },
            highlightNext() {
                if (this.highlightedIndex < this.filteredSuggestions.length - 1) {
                    this.highlightedIndex++;
                }
            },
            highlightPrevious() {
                if (this.highlightedIndex > 0) {
                    this.highlightedIndex--;
                }
            },
            selectHighlighted() {
                if (this.highlightedIndex >= 0 && this.highlightedIndex < this.filteredSuggestions.length) {
                    this.addSuggestion(this.filteredSuggestions[this.highlightedIndex]);
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside);
        },
        unmounted() {
            document.removeEventListener('click', this.handleClickOutside);
        },
        watch: {
            modelValue(newVal) {
                this.options = newVal;
            }
        },
    }
</script>