<template>
    <div class="form-type-0">
        <form @submit.prevent="updateAccountDetails" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="options.name"
                inputKey="invoice_name"
                type="text"
                ref="name"
                autocomplete="organization"
                required
            />

            <CustomInput
                v-model="options.address"
                inputKey="invoice_address"
                type="text"
                ref="address"
                autocomplete="street-address"
                required
            />

            <CustomInput
                v-model="options.postal_code"
                inputKey="invoice_postal_code"
                type="text"
                ref="postal_code"
                autocomplete="postal-code"
                required
            />

            <CustomInput
                v-model="options.city"
                inputKey="invoice_city"
                type="text"
                ref="city"
                autocomplete="address-level2"
                required
            />

            <CustomSelect
                v-model="options.country"
                inputKey="invoice_country"
                :options="countryOptions"
                required
            />

            <CustomInput
                v-model="options.vat"
                inputKey="invoice_vat"
                type="text"
                ref="vat"
                pattern="^[A-Z]{2}[A-Z0-9]{7,15}$"
                :required="options.country != 'nl'"
            />

            <CustomInput
                v-model="options.po_number"
                inputKey="invoice_po_number"
                type="text"
                ref="po_number"
            />

            <CustomToggle
                v-model="options.consent"
                inputKey="options_legal"
                ref="options-legal"
                :options="consentOptions"
                v-if="!hideConsent"
            />

            <button v-if="!hideSaveButton || hasChanged" type="submit" :disabled="loading || !hasChanged">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.invoice_details.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.invoice_details.error') }}</p>
            <p v-if="formSuccess" class="form-success">{{ $t('forms.invoice_details.success') }}</p>
        </form>
    </div>
</template>

<script>
    import { useAccountStore } from '@/stores/account';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import CustomSelect from '@/components/elements/inputs/CustomSelect';
    import CustomToggle from '@/components/elements/inputs/CustomToggle';
    import formMixin from '@/mixins/formMixin'

    export default {
        mixins: [formMixin],
        emits: ['saved'],
        props: {
            hideSaveButton: {
                type: Boolean,
                default: false
            },
            hideConsent: {
                type: Boolean,
                default: false
            },
            tcOnly: {
                type: Boolean,
                default: false
            }
        },
        components: {
            CustomInput,
            CustomSelect,
            CustomToggle
        },
        data() {
            return {
                optionsDefault: {
                    name: '',
                    address: '',
                    postal_code: '',
                    city: '',
                    vat: '',
                    po_number: '',
                    country: 'nl',
                    consent: this.tcOnly ? ['tc'] : ['tc', 'disclaimer'],
                },
                options: {},
                countryOptions: [
                    { value: 'nl', text: 'fields.invoice_country.option_nl' },
                    { value: 'be', text: 'fields.invoice_country.option_be' },
                    { value: 'de', text: 'fields.invoice_country.option_de' },
                    { value: 'fr', text: 'fields.invoice_country.option_fr' },
                    { value: 'lu', text: 'fields.invoice_country.option_lu' },
                ],
                consentOptions: this.tcOnly ?
                [
                    { value: 'tc', text: 'fields.options_invoice_legal.options.tc', required: !this.hideConsent }
                ] :
                [
                    { value: 'tc', text: 'fields.options_invoice_legal.options.tc', required: !this.hideConsent },
                    { value: 'disclaimer', text: 'fields.options_invoice_legal.options.disclaimer', required: !this.hideConsent },
                ],
            };
        },
        computed: {
            accountStore() {
                return useAccountStore();
            }
        },
        methods: {
            async updateAccountDetails() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.loading = true;

                    const data = {
                        name: this.options.name,
                        // address: this.options.address,
                        // postal_code: this.options.postal_code,
                        // city: this.options.city,
                        // vat: this.options.vat,
                        // po_number: this.options.po_number,
                        // country: this.options.country,
                    };

                    const success = await this.accountStore.updateAccount(data);
                    this.loading = false;

                    if (success) {
                        this.formSuccess = true;
                        this.formError = false;
                        this.hasChanged = false;
                        this.$emit('saved');
                    } else {
                        this.formSuccess = false;
                        this.formError = true;
                    }
                } else {
                    this.validateAllFields();
                }
            },
            setDefault() {
                this.options = { ...this.optionsDefault };
                this.options.name = this.accountStore.account.name;
            }
        },
        created() {
            this.setDefault();
        },
    }
</script>
