<template>
    <div class="form-type-0">
        <form @submit.prevent="updateAccountDetails" @input="onInputChange" :disabled="!isAdmin" ref="form" novalidate>
            <CustomInput
                v-model="name"
                inputKey="name"
                type="text"
                ref="name"
                autocomplete="organization"
                :disabled="!isAdmin"
                required
            />

            <CustomInput
                v-model="url"
                inputKey="url"
                type="url"
                ref="name"
                autocomplete="url"
                :disabled="!isAdmin"
                required
            />

            <button v-if="!hideSaveButton || hasChanged" type="submit" :disabled="loading || !isAdmin">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.account_details.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.account_details.error') }}</p>
            <p v-if="formSuccess" class="form-success">{{ $t('forms.account_details.success') }}</p>
        </form>
    </div>
</template>

<script>
    import { useAccountStore } from '@/stores/account';
    import { useUserStore } from '@/stores/user';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import formMixin from '@/mixins/formMixin'
    

    export default {
        mixins: [formMixin],
        props: {
            hideSaveButton: {
                type: Boolean,
                default: false
            }
        },
        components: {
            CustomInput
        },
        data() {
            return {
                name: '',
                url: ''
            };
        },
        computed: {
            userStore() {
                return useUserStore();
            },
            accountStore() {
                return useAccountStore();
            },
            isAdmin() {
                return this.accountStore.isAccountAdmin || this.userStore.isPartnerAdmin;
            },
        },
        created() {
            this.name = this.accountStore.account.name;
            this.url = this.accountStore.account.url;
        },
        methods: {
            async updateAccountDetails() {
                if(!this.isAdmin) { return; }

                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.loading = true;
                    const success = await this.accountStore.updateAccount({
                        name: this.name,
                        url: this.url
                    });
                    this.loading = false;

                    if (success) {
                        this.formSuccess = true;
                        this.formError = false;
                        this.hasChanged = false;

                    } else {
                        this.formSuccess = false;
                        this.formError = true;
                    }
                } else {
                    this.validateAllFields();
                }
            }
        }
    }
</script>
