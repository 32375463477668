<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @loading-start="handleLoadingStart"
        :paywall="paywall"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_siterank.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div v-if="hasWebrank" class="row row-content row-native-cols">
                <div v-if="webrank.perc" class="col-12 col-md-6">
                    <div class="gauge-large">
                        <GraphGauge :value="Math.round(parseFloat(webrank.perc) * 100)" />
                        <div class="right">
                            <div class="score">
                                {{ formatNumber(webrank.rank) }}
                            </div>
                            <div class="title">
                                {{ $t('components.prospect_siterank.webrank_intl')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="webrank.country_perc" class="col-12 col-md-6">
                    <div class="gauge-large">
                        <GraphGauge :value="Math.round(parseFloat(webrank.country_perc) * 100)" />
                        <div class="right">
                            <div class="score">
                                {{ formatNumber(webrank.country_rank) }}
                            </div>
                            <div class="title">
                                {{ $t('components.prospect_siterank.webrank_nl')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="hasLighthouse" class="row row-content row-native-cols">
                <div v-if="siterank.details?.pagespeed_mobile_score?.performance" class="col-6 col-md-4 col-lg-3">
                    <div class="gauge">
                        <GraphGauge :value="Math.round(parseFloat(siterank.details.pagespeed_mobile_score.performance) * 100)" />
                        <div class="title">{{ $t('components.prospect_siterank.lh_performance')}}</div>
                    </div>
                </div>
                <div v-if="siterank.details?.pagespeed_mobile_score?.seo" class="col-6 col-md-4 col-lg-3">
                    <div class="gauge">
                        <GraphGauge :value="Math.round(parseFloat(siterank.details.pagespeed_mobile_score.seo) * 100)" />
                        <div class="title">{{ $t('components.prospect_siterank.lh_seo')}}</div>
                    </div>
                </div>
                <div v-if="siterank.details?.pagespeed_mobile_score?.accessibility" class="col-6 col-md-4 col-lg-3">
                    <div class="gauge">
                        <GraphGauge :value="Math.round(parseFloat(siterank.details.pagespeed_mobile_score.accessibility) * 100)" />
                        <div class="title">{{ $t('components.prospect_siterank.lh_accessibility')}}</div>
                    </div>
                </div>
                <div v-if="siterank.details?.pagespeed_mobile_score?.best_practices" class="col-6 col-md-4 col-lg-3">
                    <div class="gauge">
                        <GraphGauge :value="Math.round(parseFloat(siterank.details.pagespeed_mobile_score.best_practices) * 100)" />
                        <div class="title">{{ $t('components.prospect_siterank.lh_best_practices')}}</div>
                    </div>
                </div>
            </div>

            <div v-if="hasStatistics" class="row row-content">
                <div class="section">
                    <ul class="specs">
                        <li v-if="siterank?.details?.monthly_visits">
                            <div class="title">
                                {{ $t('components.prospect_siterank.monthly_visits')}}*
                            </div>
                            <div class="content capitalize">
                                {{ formatNumber(siterank.details.monthly_visits) }}
                            </div>
                        </li>
                        <li v-if="siterank?.details?.bounce_rate">
                            <div class="title">
                                {{ $t('components.prospect_siterank.bounce_rate')}}*
                            </div>
                            <div class="content capitalize">
                                {{ formatNumber(siterank.details.bounce_rate * 100, '', '%') }}
                            </div>
                        </li>
                        <li v-if="siterank?.details?.time_on_site">
                            <div class="title">
                                {{ $t('components.prospect_siterank.time_on_site')}}*
                            </div>
                            <div class="content capitalize">
                                {{ formatNumber(siterank.details.time_on_site, '', 's') }}
                            </div>
                        </li>
                        <li v-if="siterank?.details?.visited_pages">
                            <div class="title">
                                {{ $t('components.prospect_siterank.visited_pages')}}*
                            </div>
                            <div class="content capitalize">
                                {{ formatNumber(siterank.details.visited_pages) }}
                            </div>
                        </li>
                        <li v-if="siterank?.details?.pagerank">
                            <div class="title">
                                {{ $t('components.prospect_siterank.pagerank')}}
                            </div>
                            <div class="content capitalize">
                                {{ formatNumber(siterank.details.pagerank) }}
                            </div>
                        </li>
                        <li v-if="siterank?.details?.backlinks">
                            <div class="title">
                                {{ $t('components.prospect_siterank.backlinks')}}
                            </div>
                            <div class="content capitalize">
                                {{ formatNumber(siterank.details.backlinks) }}
                            </div>
                        </li>
                        <li v-if="siterank?.details?.backlink_domains">
                            <div class="title">
                                {{ $t('components.prospect_siterank.backlink_domains')}}
                            </div>
                            <div class="content capitalize">
                                {{ formatNumber(siterank.details.backlink_domains) }}
                            </div>
                        </li>
                        <li v-if="siterank?.details?.indexable_urls">
                            <div class="title">
                                {{ $t('components.prospect_siterank.indexable_urls')}}
                            </div>
                            <div class="content capitalize">
                                {{ formatNumber(siterank.details.indexable_urls) }}
                            </div>
                        </li>
                        <li class="disclaimer" v-if="siterank?.details?.monthly_visits || siterank?.details?.bounce_rate || siterank?.details?.time_on_site || siterank?.details?.visited_pages">
                            * {{ $t('components.prospect_siterank.disclaimer')}}
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { useAccountStore } from '@/stores/account';
    import { fetchUrl } from '@/services/ppApi';
    import WidgetSection from '@/components/sections/WidgetSection';
    import GraphGauge from '@/components/elements/misc/GraphGauge';
    import { formatNumber } from '@/utils/formatting';

    export default {
        name: "ProspectSiteRank",
        components: {
            WidgetSection,
            GraphGauge
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            },
            paywall: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                loaded: false
            }
        },
        computed: {
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            },
            siterank() {
                return useProspectStore().siterank;
            },
            hide() {
                return !this.siterank?.api_urls || !this.loading && this.loaded && (!this.hasLighthouse && !this.hasStatistics);
            },
            hasLighthouse() {
                const details = this.siterank?.details || {};
                return Object.keys(details).some(key => key.startsWith('pagespeed_'));
            },
            hasStatistics() {
                const details = this.siterank?.details || {};
                const keys = ['monthly_visits', 'bounce_rate', 'time_on_site', 'visited_pages', 'pagerank', 'backlinks', 'backlink_domains', 'indexable_urls'];
                const validStats = keys.filter(key => key in details && details[key]);
                return validStats.length >= 3;
            },
            hasWebrank() {
                const details = this.siterank?.details?.rankings || {};
                return Object.values(details).some(detail => detail.name === 'webrank');
            },
            webrank() {
                const details = this.siterank?.details?.rankings || {};
                return Object.values(details).find(detail => detail.name === 'webrank');
            }
        },
        methods: {
            formatNumber,
            async fetchSiterank() {
                if (!this.siterank || (this.siterank.api_urls && this.siterank.api_urls.length < 1) || this.loaded) {
                    return;
                }
                this.loading = true;

                try {
                    const response = await fetchUrl(this.siterank.api_urls[0]);
                    this.siterank.details = response.data;
                    this.loaded = true;
                } catch {
                    this.siterank?.details ? this.siterank.details = null : '';
                    this.loaded = true; // Potential improvement: manual retry
                } finally {
                    this.loading = false;
                }
            },
            async handleLoadingStart() {
                if (!this.paywallActive && !this.loaded) {
                    await this.fetchSiterank();
                    if(!this.hide) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                }
            }
        },
        mounted() {
            if(!this.paywallActive && !this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchSiterank();
            }
        }
    }
</script>