<template>
    <div class="container-fluid options-type-3">
        <div class="form-type-2">
            <OptionsSearch v-model="options.search" @update:modelValue="onChanged" />
            <OptionsEmployees v-model="options.employees" @update:modelValue="onChanged" />
            <OptionsFounded v-model="options.founded" @update:modelValue="onChanged" />
            <OptionsLocation v-model="options.location" @update:modelValue="onChanged" />
            <OptionsApps v-model="options.apps" @update:modelValue="onChanged" />
            <OptionsSocials v-model="options.socials" @update:modelValue="onChanged" />
        </div>
        <div class="actions">
            <button class="btn-m-icon" :class="[hasDefaultOptions ? 'btn-disabled' : 'btn-4']" :disabled="hasDefaultOptions" v-if="!loading" @click="resetOptions">
                <div class="icon-reset-pos"></div>
            </button>
            <div class="loading-spinner" v-if="loading">
                <div class="loading-spinner-type-2"></div>
            </div>
            <button class="btn-m" :class="[hasDefaultOptions || loading ? 'btn-disabled' : 'btn-1']" :disabled="hasDefaultOptions || loading" @click="loadFirstProspect">Start met prospecten.</button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useAccountStore } from '@/stores/account';
    import { useSuspectStore } from '@/stores/suspect';
    import { useProspectStore } from '@/stores/prospect';
    import { usePopupStore } from '@/stores/popup';
    import OptionsSearch from '@/components/elements/suspect/OptionsSearch';
    import OptionsSocials from '@/components/elements/suspect/OptionsSocials';
    import OptionsLocation from '@/components/elements/suspect/OptionsLocation';
    import OptionsApps from '@/components/elements/suspect/OptionsApps';
    import OptionsEmployees from '@/components/elements/suspect/OptionsEmployees';
    import OptionsFounded from '@/components/elements/suspect/OptionsFounded';

    export default {
        name: "OnboardingOptions",
        components: {
            OptionsSearch,
            OptionsSocials,
            OptionsLocation,
            OptionsApps,
            OptionsEmployees,
            OptionsFounded
        },
        computed: {
            ...mapState(useSuspectStore, ['options', 'total', 'loading', 'defaultOptions', 'hasDefaultOptions']),
        },
        methods: {
            ...mapActions(useSuspectStore, ['updateOptions', 'resetOptions', 'saveUserSuspectSettings', 'fetchSuspect']),
            onChanged() {
                this.updateOptions(this.options);
                this.saveUserSuspectSettings();
            },
            async loadFirstProspect() {
                await this.fetchSuspect();
                this.processQueryResults();
            },
            processQueryResults() {
                if(useProspectStore().prospect && this.total > 10) {
                    this.proceedPopup();
                } else {
                    this.noResultsPopup();
                }
            },
            proceed() {
                useAccountStore().account.onboarding_status = 1; // Assign non-persistent value for onboarding.
                this.$router.push({ name: "suspect" });
            },
            noResultsPopup() {
                usePopupStore().showPopup('confirm', {
                    title: 'popups.onboarding_no_results.title',
                    description: 'popups.onboarding_no_results.description',
                    btnConfirm: "popups.onboarding_no_results.button_confirm",
                    btnCancel: "popups.onboarding_no_results.button_cancel",
                    confirmFunction: this.resetOptions,
                    danger: true
                });
            },
            proceedPopup() {
                usePopupStore().showPopup('confirm', {
                    title: 'popups.onboarding_proceed.title',
                    description: 'popups.onboarding_proceed.description',
                    btnConfirm: "popups.onboarding_proceed.button_confirm",
                    showBtnCancel: false,
                    success: true,
                    confirmFunction: this.proceed,
                });
            }
        }
    }
</script>