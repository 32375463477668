<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <OrderManager />
    </AccountViewBase>
</template>

<script>
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import OrderManager from '@/components/account/order/OrderManager';

    export default {
        name: 'SubscriptionsView',
        components: {
            AccountViewBase,
            OrderManager
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.account_order.seo_title',
            };
        }
    }
</script>
