<template>
    <section :class="['container-fluid', 'content-type-15', !paywallActive && toggleable ? 'toggleable' : '', loading ? 'loading' : '', cssClass, paywallActive || toggle ? 'expanded' : 'collapsed', $slots['section-actions'] ? 'has-actions' : '']" ref="sectionContainer">
        <div class="container">
            <div class="row row-title" @click="toggleSection">
                <div class="section-title">
                    <slot name="section-title"></slot>
                </div>
                <div class="section-actions" @click.stop>
                    <slot v-if="!$slots['section-noresults'] && toggle && !paywallActive" name="section-actions"></slot>
                    <div @click="toggleSection" v-if="!paywallActive && toggleable && !loading" class="toggle-button" :class="[toggle ? 'visible' : 'hidden']">
                        <div class="toggle-icon"></div>
                    </div>
                    <router-link :to="{ name: 'subscriptions' }" v-if="paywallActive" class="paywall-button">
                        <div class="icon-lock-pos"></div>
                    </router-link>
                    <div v-if="loading && !toggle" class="loading-icon">
                        <div class="loading-spinner-type-2"></div>
                    </div>
                </div>
            </div>

            <slot v-if="!loading && toggle && loaded" name="section-content"></slot>

            <div v-if="!loading && $slots['section-noresults'] && toggle" class="row row-noresults">
                <div class="section-noresults">
                    <slot name="section-noresults"></slot>
                </div>
            </div>

            <div v-if="loading && toggle" class="row row-loading">
                <LoadingSection :showMessage="showMessage" :message="loadingText" />
            </div>
        </div>
    </section>
</template>

<script>
    import { useAccountStore } from '@/stores/account';
    import LoadingSection from '@/components/sections/LoadingSection';

    export default {
        name: "WidgetSection",
        emits: ['toggle', 'loading-start'],
        components: {
           LoadingSection 
        },
        props: {
            loaded: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            loadingText: {
                type: String,
                default: null
            },
            toggleable: {
                type: Boolean,
                default: true
            },
            defaultToggle: {
                type: Boolean,
                default: true
            },
            cssClass: {
                type: String,
                required: false
            },
            paywall: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                toggle: this.defaultToggle || !this.toggleable
            }
        },
        computed: {
            showMessage() {
                return !!this.loadingText;
            },
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            },
        },
        methods: {
            toggleSection() {
                if(this.paywallActive) { return; }

                if (this.toggleable && this.loaded && !this.loading) {
                    this.toggle = !this.toggle;
                    this.$emit('toggle', this.toggle);
                } else if (this.toggleable && !this.loading) {
                    this.$emit('loading-start', this.toggle);
                }
            },
        },
        mounted() {
            if(this.paywallActive) {
                this.toggle = false;
            }
        }
    }
</script>