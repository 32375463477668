<template>
    <div class="container-fluid content-type-22">
        <div class="container">
            <div class="row row-steps">
                <div class="col-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                    <ProcessSteps :steps="steps" @navigateToStep="navigateToStep" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-10 col-md-offset-1 col-xl-8 col-xl-offset-2">
                    <div class="page-title">
                        <h1>{{ currentStep ? $t(`views.account_order.steps.${currentStep.key}.title`) : '' }}</h1>
                        <p>{{ currentStep ? $t(`views.account_order.steps.${currentStep.key}.description`) : '' }}</p>
                    </div>
                </div>
            </div>

            <div class="row" v-if="step === 1">
                <div class="col-12 col-sm-10 col-sm-offset-1 col-lg-12 col-lg-offset-0 col-xl-10 col-xl-offset-1">
                    <div class="content">
                        <SubscriptionForm :selectedSubscription="subscriptionType" @updated="updateSubscription" @submitted="setSubscription" />
                    </div>
                </div>
            </div>

            <div class="row" v-else-if="step === 2">
                <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg- col-lg-offset- col-xl-6 col-xl-offset-3">
                    <div class="content">
                        <InvoiceDetailsForm :hideConsent="false" :tcOnly="true" @saved="setInvoiceDetails" />
                    </div>
                </div>
            </div>

            <div class="row row-checkout" v-else-if="step === 3">
                <OrderCheckout :selectedSubscription="subscriptionType" />
            </div>
        </div>
    </div>
</template>

<script>
    import { useAccountStore } from '@/stores/account';
    import ProcessSteps from '@/components/elements/misc/ProcessSteps';
    import OrderCheckout from '@/components/account/order/OrderCheckout';
    import InvoiceDetailsForm from '@/components/forms/InvoiceDetailsForm';
    import SubscriptionForm from '@/components/forms/SubscriptionForm';
    import { scrollToTop } from '@/utils/scroll';
    import { nextTick } from 'vue';
    
    export default {
        name: 'InvoiceSettings',
        components: {
            ProcessSteps,
            OrderCheckout,
            InvoiceDetailsForm,
            SubscriptionForm
        },
        data() {
            return {
                subscriptionType: '',
                step: 0,
                defaultSteps: [
                    {
                        key: "subscription",
                        name: "Abonnement",
                    },
                    {
                        key: "invoice_details",
                        name: "Facturatie",
                    },
                    {
                        key: "summary",
                        name: "Betaling",
                    }
                ]
            }
        },
        computed: {
            accountStore() {
                return useAccountStore();
            },
            steps() {
                return this.defaultSteps.map((step, index) => ({
                    ...step,
                    current: this.step === index + 1 ? true : false,
                    clickable: this.validateClickableStep(step.key),
                    completed: this.step > index + 1 ? true : false
                }));
            },
            currentStep() {
                return this.steps[this.step - 1] || false;
            }
        },
        methods: {
            validateClickableStep(key) {
                switch (key) {
                    case 'subscription' :
                        return true;
                    case 'invoice_details':
                        return this.subscriptionType !== this.accountStore.subscription;
                    case 'summary':
                        return this.subscriptionType !== this.accountStore.subscription && this.accountStore.hasFullAccount; // REPLACE ME!
                    default:
                        return false;
                }
            },
            setSubscription(subscription) {
                this.updateSubscription(subscription);
                this.navigateToStep(2)
            },
            setInvoiceDetails() {
                this.navigateToStep(3);
            },
            updateSubscription(subscription) {
                this.subscriptionType = subscription;
            },
            findStep(key) {
                const index = this.steps.findIndex(step => step.key === key);
                return index + 1;
            },
            navigateToStep(step) {
                if(typeof step === 'number') {
                    this.step = step;
                } else if (typeof step === 'string' && step !== '') {
                    this.step = this.findStep(step) || this.step;
                }
            }
        },
        watch: {
            step(step) {
                if(step > 0) {
                    nextTick(() => {
                        scrollToTop();
                    });
                }
            }
        },
        mounted() {
            this.subscriptionType = this.accountStore.subscription;
            this.step = 1;
        }
    }
</script>