<template>
    <ul :class="['examples', cssClass]">
        <li v-for="(example, index) in displayItems" :key="index" class="example" @click="openCompanyProfile(example.id)">
            <CompanyLogo
                :domain="example.domain ? example.domain : null"
                :company_name="example.name ? example.name : example.domain ? example.domain : null"
            />
        </li>
    </ul>
</template>

<script>
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';
    import { useUserStore } from '@/stores/user';

    export default {
        name: "SearchExamples",
        components: {
            CompanyLogo
        },
        props: {
            cssClass: {
                type: String,
                default: 'search-examples-1'
            }
        },
        data() {
            return {
                examples: [
                    {
                        id: "310473440000",
                        name: "Booking.com B.V.",
                        domain: "booking.com"
                    },
                    {
                        id: "528349800000",
                        name: "Frankwatching B.V.",
                        domain: "frankwatching.com",
                    },
                    {
                        id: "321495130000",
                        name: "Emerce B.V.",
                        domain: "emerce.nl"
                    },
                    {
                        id: "559956080000",
                        name: "Adyen International B.V.",
                        domain: "adyen.com",
                    },
                    {
                        id: "859159710000",
                        name: "Mollie International B.V.",
                        domain: "mollie.com"
                    }
                ]
            };
        },
        computed: {
            history() {
                return useUserStore().searchHistory;
            },
            displayItems() {
                let items = [...this.history];
                const itemsNeeded = 8 - items.length;

                if (itemsNeeded > 0) {
                    const historyIds = new Set(items.map(item => item.id));
                    const filteredExamples = this.examples.filter(
                        example => !historyIds.has(example.id)
                    );

                    const examplesToAdd = filteredExamples.slice(0, itemsNeeded);
                    items = items.concat(examplesToAdd);
                }

                return items;
            },
        },
        methods: {
            openCompanyProfile(id) {
                if(id) {
                    this.$router.push({ name: 'search-result', params: { id: id } });
                }
            },
        }
    }
</script>