<template>
    <div class="form-type-0">
        <form @submit.prevent="setSubscriptionType" @input="onInputChange" ref="form" novalidate>
            <CustomRadio
                v-model="options.subscription"
                @update:modelValue="onChanged"
                inputKey="subscriptions"
                ref="subscriptions"
                :options="subscriptionsOptions"
                cssClass="custom-radio-type-4"
                :hideNonSelected="hideNonSelected"
                required
            />

            <button v-if="(!hideSaveButton || newSubscriptionSelected) && !hideNonSelected" type="submit" :disabled="loading || !newSubscriptionSelected">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.invoice_details.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.invoice_details.error') }}</p>
            <p v-if="formSuccess" class="form-success">{{ $t('forms.invoice_details.success') }}</p>
        </form>
    </div>
</template>

<script>
    import { useAccountStore } from '@/stores/account';
    import CustomRadio from '@/components/elements/inputs/CustomRadio';
    import formMixin from '@/mixins/formMixin';

    export default {
        mixins: [formMixin],
        emits: ['updated', 'submitted'],
        props: {
            hideSaveButton: {
                type: Boolean,
                default: false
            },
            hideNonSelected: {
                type: Boolean,
                default: false
            },
            selectedSubscription: {
                type: String,
                required: false
            }
        },
        components: {
            CustomRadio
        },
        data() {
            return {
                optionsDefault: {
                    subscription: '',
                },
                options: {},
                subscriptionsOptions: [
                    {
                        value: 'free',
                        text: 'fields.options_subscriptions.options.free.text',
                        description: 'fields.options_subscriptions.options.free.description',
                        price: 'fields.options_subscriptions.options.free.price',
                        image: 'account',
                        bullets: [
                            {
                                text: 'fields.options_subscriptions.options.free.bullets.one',
                            },
                            {
                                text: 'fields.options_subscriptions.options.free.bullets.two',
                            },
                            {
                                text: 'fields.options_subscriptions.options.free.bullets.three',
                                class: 'cross'
                            },
                            {
                                text: 'fields.options_subscriptions.options.free.bullets.four',
                                class: 'cross'
                            }
                        ]
                    },
                    {
                        value: 'freelance',
                        text: 'fields.options_subscriptions.options.freelancer.text',
                        description: 'fields.options_subscriptions.options.freelancer.description',
                        price: 'fields.options_subscriptions.options.freelancer.price',
                        image: 'cards',
                        bullets: [
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.one',
                            },
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.two',
                            },
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.three',
                            },
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.four',
                            },
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.five',
                            }
                        ]
                    },
                    {
                        value: 'professional',
                        text: 'fields.options_subscriptions.options.unlimited.text',
                        description: 'fields.options_subscriptions.options.unlimited.description',
                        price: 'fields.options_subscriptions.options.unlimited.price',
                        image: 'infinity',
                        bullets: [
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.one',
                            },
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.two',
                            },
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.three',
                            },
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.four',
                            },
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.five',
                            }
                        ]
                    },
                ]
            };
        },
        computed: {
            accountStore() {
                return useAccountStore();
            },
            newSubscriptionSelected() {
                return this.accountStore.subscription !== this.options.subscription;
            }
        },
        methods: {
            setSubscriptionType() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.$emit('submitted', this.options.subscription);
                } else {
                    this.validateAllFields();
                }
            },
            onChanged(val) {
                this.$emit('updated', val);
            },
            setDefault() {
                this.options = { ...this.optionsDefault };
                this.options.subscription = this.selectedSubscription || this.accountStore.subscription;

                const index = this.subscriptionsOptions.findIndex(item => item.value === this.accountStore.subscription);
                if (index !== -1) {
                    switch (this.options.subscription) {
                        case 'free':
                            this.subscriptionsOptions[index].notice = {
                                label: 'fields.options_subscriptions.notice.duration_unlimited',
                                translationLabels: {}
                            };
                            break;
                        case 'freelance':
                            this.subscriptionsOptions[index].notice = {
                                label: 'fields.options_subscriptions.notice.duration_unlimited',
                                translationLabels: {}
                            };
                            break;
                        case 'professional':
                            this.subscriptionsOptions[index].notice = {
                                label: 'fields.options_subscriptions.notice.duration_unlimited',
                                translationLabels: {}
                            };
                            break;
                        default:
                            this.subscriptionsOptions[index].notice = {
                                label: 'fields.options_subscriptions.notice.duration_unlimited',
                                translationLabels: {}
                            };
                            break;
                    }
                }
            }
        },
        created() {
            this.setDefault();
        },
    }
</script>
