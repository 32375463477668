import i18n from '@/plugins/i18n';

export function formatNumber(input, prefix = '', suffix = '') {
    if (!input || (typeof input !== 'string' && typeof input !== 'number')) {
        return input;
    }
    const numberString = input.toString().replace(/[^\d.-]/g, '');
    const number = parseFloat(numberString);

    if (isNaN(number)) {
        return input;
    }

    const formattedNumber = new Intl.NumberFormat(i18n.global.locale).format(number);

    return `${prefix}${formattedNumber}${suffix}`;
}

export function formatDateReverse(date) {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
}

export function formatTextRange(input, prefix = '', suffix = '') {
    if (!input || (typeof input !== 'string' && typeof input !== 'number')) {
        return input;
    }

    let elements = input.split(':');
    let outputElements = [];

    elements.forEach((e) => {
        if(e.includes('+')) {
            e = e.replace('+', '');
            outputElements.push(`${prefix}${formatNumber(e, '', suffix + '+')}`);
        } else {
            outputElements.push(`${prefix}${formatNumber(e)}${suffix}`);
        }
    });
    return outputElements.join(' - ');
}