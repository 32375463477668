<template>
    <div id="background-type-1" class="background-type-1">
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
            <defs>
                <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5">
                    <stop offset="0%" stop-color="rgba(254, 93, 38, 1)"></stop>
                    <stop offset="100%" stop-color="rgba(254, 93, 38, 0)"></stop>
                </radialGradient>
                <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5">
                    <stop offset="0%" stop-color="rgba(0, 117, 242, 1)"></stop>
                    <stop offset="100%" stop-color="rgba(0, 117, 242, 0)"></stop>
                </radialGradient>
                <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5">
                    <stop offset="0%" stop-color="rgba(214, 235, 255, 1)"></stop>
                    <stop offset="100%" stop-color="rgba(214, 235, 255, 0)"></stop>
                </radialGradient>
                <radialGradient id="Gradient4" cx="50%" cy="50%" fx="4.56417%" fy="50%" r=".5">
                    <stop offset="0%" stop-color="rgba(254, 93, 38, 1)"></stop>
                    <stop offset="100%" stop-color="rgba(254, 93, 38, 0)"></stop>
                </radialGradient>
                <radialGradient id="Gradient5" cx="50%" cy="50%" fx="2.65405%" fy="50%" r=".5">
                    <stop offset="0%" stop-color="rgba(0, 117, 242, 1)"></stop>
                    <stop offset="100%" stop-color="rgba(0, 117, 242, 0)"></stop>
                </radialGradient>
                <radialGradient id="Gradient6" cx="50%" cy="50%" fx="0.981338%" fy="50%" r=".5">
                    <stop offset="0%" stop-color="rgba(214, 235, 255, 1)"></stop>
                    <stop offset="100%" stop-color="rgba(214, 235, 255, 0)"></stop>
                </radialGradient>
            </defs>
            <rect x="12%" y="7%" width="100%" height="100%" fill="url(#Gradient4)" transform="rotate(20 50 50)"></rect>
            <rect x="-8%" y="20%" width="100%" height="100%" fill="url(#Gradient5)" transform="rotate(40 50 50)"></rect>
            <rect x="-15%" y="-8%" width="100%" height="100%" fill="url(#Gradient6)" transform="rotate(60 50 50)"></rect>
            <rect x="14%" y="10%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(45 50 50)"></rect>
            <rect x="-10%" y="40%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(70 50 50)"></rect>
            <rect x="8%" y="25%" width="100%" height="100%" fill="url(#Gradient3)" transform="rotate(80 50 50)"></rect>
        </svg>
    </div>
</template>

<script>
  export default {
        name: 'ViewBackground'
  }
</script>