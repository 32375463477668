<template>
    <ProspectHeader :showLabel="true" />
    <component
        v-for="(widget, index) in availableWidgets"
        :key="index"
        :is="widget.component"
        v-bind="widget.props"
    />
</template>

<script>
    import ProspectHeader from '@/components/elements/prospect/ProspectHeader';
    import ProspectAiWidget from '@/components/elements/prospect/ProspectAiWidget';
    import ProspectFirmologyAlt from '@/components/elements/prospect/ProspectFirmologyAlt';
    import ProspectContactInfo from '@/components/elements/prospect/ProspectContactInfo';
    import ProspectOpeningHoursAlt from '@/components/elements/prospect/ProspectOpeningHoursAlt';
    import ProspectIndustries from '@/components/elements/prospect/ProspectIndustries';
    import ProspectReviewsSummaryAlt from '@/components/elements/prospect/ProspectReviewsSummaryAlt';
    import ProspectSiteSearch from '@/components/elements/prospect/ProspectSiteSearch';
    import ProspectPeople from '@/components/elements/prospect/ProspectPeople';
    import ProspectFamily from '@/components/elements/prospect/ProspectFamily';
    import ProspectProperty from '@/components/elements/prospect/ProspectProperty';
    import ProspectSameAddress from '@/components/elements/prospect/ProspectSameAddress';
    import ProspectReviewsDetails from '@/components/elements/prospect/ProspectReviewsDetails';
    import ProspectNews from '@/components/elements/prospect/ProspectNews';
    import ProspectVacancies from '@/components/elements/prospect/ProspectVacancies';
    import ProspectAppsDetails from '@/components/elements/prospect/ProspectAppsDetails';
    import ProspectSiteRank from '@/components/elements/prospect/ProspectSiteRank';
    import ProspectContacts from '@/components/elements/prospect/ProspectContacts';
    import ProspectPageViews from '@/components/elements/prospect/ProspectPageViews';
    import ProspectTags from '@/components/elements/prospect/ProspectTags';
    import ProspectAssignment from '@/components/elements/prospect/ProspectAssignment';
    import ProspectEvents from '@/components/elements/prospect/ProspectEvents';

    export default {
        name: "ProspectDetails",
        components: {
            ProspectHeader,
            ProspectAiWidget,
            ProspectFirmologyAlt,
            ProspectContactInfo,
            ProspectOpeningHoursAlt,
            ProspectIndustries,
            ProspectReviewsSummaryAlt,
            ProspectSiteSearch,
            ProspectPeople,
            ProspectFamily,
            ProspectProperty,
            ProspectSameAddress,
            ProspectReviewsDetails,
            ProspectNews,
            ProspectVacancies,
            ProspectAppsDetails,
            ProspectSiteRank,
            ProspectContacts,
            ProspectPageViews,
            ProspectTags,
            ProspectAssignment,
            ProspectEvents
        },
        data() {
            return {
                availableWidgets: [
                    {
                        id: 'ProspectAiWidget_summary',
                        component: 'ProspectAiWidget',
                        props: { slug: 'summary', defaultToggle: true, auto: false },
                    },
                    {
                        id: 'ProspectFirmologyAlt',
                        component: 'ProspectFirmologyAlt',
                        props: { defaultToggle: true },
                    },
                    {
                        id: 'ProspectContactInfo',
                        component: 'ProspectContactInfo',
                        props: { defaultToggle: true },
                    },
                    {
                        id: 'ProspectOpeningHoursAlt',
                        component: 'ProspectOpeningHoursAlt',
                        props: { defaultToggle: false },
                    },
                    {
                        id: 'ProspectAssignment',
                        component: 'ProspectAssignment',
                        props: { defaultToggle: false },
                    },
                    {
                        id: 'ProspectTags',
                        component: 'ProspectTags',
                        props: { defaultToggle: true, auto: false },
                    },
                    {
                        id: 'ProspectEvents',
                        component: 'ProspectEvents',
                        props: { defaultToggle: false, auto: false },
                    },
                    {
                        id: 'ProspectReviewsSummaryAlt',
                        component: 'ProspectReviewsSummaryAlt',
                        props: { defaultToggle: true },
                    },
                    {
                        id: 'ProspectIndustries',
                        component: 'ProspectIndustries',
                        props: { defaultToggle: true },
                    },
                    {
                        id: 'ProspectAiWidget_summary_fundamentals',
                        component: 'ProspectAiWidget',
                        props: { slug: 'fundamentals', defaultToggle: false, auto: false },
                    },
                    {
                        id: 'ProspectSiteSearch',
                        component: 'ProspectSiteSearch',
                        props: { defaultToggle: true, auto: false },
                    },
                    {
                        id: 'ProspectContacts',
                        component: 'ProspectContacts',
                        props: { defaultToggle: true, auto: false },
                    },
                    {
                        id: 'ProspectPeople',
                        component: 'ProspectPeople',
                        props: { defaultToggle: true, auto: false },
                    },
                    {
                        id: 'ProspectFamily',
                        component: 'ProspectFamily',
                        props: { defaultToggle: true, auto: false },
                    },
                    {
                        id: 'ProspectProperty',
                        component: 'ProspectProperty',
                        props: { defaultToggle: false, auto: false },
                    },
                    {
                        id: 'ProspectSameAddress',
                        component: 'ProspectSameAddress',
                        props: { defaultToggle: true, auto: false },
                    },
                    {
                        id: 'ProspectReviewsDetails',
                        component: 'ProspectReviewsDetails',
                        props: { defaultToggle: false },
                    },
                    {
                        id: 'ProspectNews',
                        component: 'ProspectNews',
                        props: { defaultToggle: true, auto: false },
                    },
                    {
                        id: 'ProspectVacancies',
                        component: 'ProspectVacancies',
                        props: { defaultToggle: false, auto: false },
                    },
                    {
                        id: 'ProspectAppsDetails',
                        component: 'ProspectAppsDetails',
                        props: { defaultToggle: true },
                    },
                    {
                        id: 'ProspectSiteRank',
                        component: 'ProspectSiteRank',
                        props: { defaultToggle: true, auto: false },
                    },
                ],
            };
        }
    }
</script>