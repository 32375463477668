<template>
    <ToggleSimple>
        <template #toggle>
            <div class="left">
                <div v-if="$te(`fields.${name}.label`)" class="toggle-text">
                    {{ $t(`fields.${name}.label`) }}

                    <span v-if="isActive">
                        <div class="icon-check-dark"></div>
                    </span>
                </div>
            </div>
            <div class="right">
                <div v-if="isActive" class="reset" @click="resetSection">
                    <div class="icon-reset-pos"></div>
                </div>
                <div class="toggle-icon"></div>
            </div>
        </template>

        <template #content>
            <CustomRange
                inputKey="employees"
                :modelValue="employees"
                :min="min"
                :max="max"
                :showLabel="false"
                :showLabelMinMax="true"
                @update:modelValue="onRangeChanged"
            />
        </template>
    </ToggleSimple>
</template>

<script>
    import ToggleSimple from '@/components/elements/misc/ToggleSimple';
    import CustomRange from '@/components/elements/inputs/CustomRange';

    export default {
        name: "OptionsEmployees",
        components: {
            ToggleSimple,
            CustomRange
        },
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            cssClass: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                name: 'employees',
                min: 0,
                max: 1000,
                employees: {
                    min: this.modelValue.includes(':') ? parseInt(this.modelValue.split(':')[0]) : this.min,
                    max: this.modelValue.includes(':') ? parseInt(this.modelValue.split(':')[1]) : this.max
                }
            };
        },
        computed: {
            isActive() {
                return this.modelValue !== '';
            }
        },
        methods: {
            resetSection(event = null) {
                if (event) {
                    event.stopPropagation()
                }
                this.employees = { min: '', max: '' };
                this.$emit('update:modelValue', '');
            },
            onRangeChanged(value) {
                var { min, max } = value;
                let employees = '';

                if(min > 950 && max === 1000) {
                    max = 100000;
                }
                
                if(min && min !== '' && max && max !== '') {
                    employees = `${min}:${max}`;
                } else if(min && min !== '') {
                    employees = `${min}:100000`;
                } else if(max && max !== '') {
                    employees = `0:${max}`;
                }
                this.$emit('update:modelValue', employees);
            }
        },
        watch: {
            modelValue(newVal) {
                const [min, max] = newVal.split(':').map(Number);
                this.employees = { min, max };
            }
        },
    }
</script>
