<template>
    <div class="form-type-0">
        <form @submit.prevent="addContact" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="name_first"
                inputKey="contact_name_first"
                type="text"
                ref="name_first"
                required
            />
            <CustomInput
                v-model="name_last"
                inputKey="contact_name_last"
                type="text"
                ref="name_last"
                required
            />
            <CustomInput
                v-model="job_title"
                inputKey="contact_job_title"
                type="text"
                ref="job_title"
            />
            <CustomInput
                v-model="email"
                inputKey="contact_email"
                type="email"
                ref="email"
            />
            <CustomInput
                v-model="phone"
                inputKey="contact_phone"
                type="text"
                ref="phone"
            />
            <CustomInput
                v-model="linkedin_link"
                inputKey="contact_linkedin_link"
                type="text"
                ref="linkedin"
            />
            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.create_contact.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.create_contact.error') }}</p>
        </form>
    </div>
</template>

<script>
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import formMixin from '@/mixins/formMixin';
    import { useProspectStore } from '@/stores/prospect';

    export default {
        name: "AddContactForm",
        mixins: [formMixin],
        props: {
            person: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                contact_id: this.person?.contact_id || '',
                name_first: this.person?.name_first || '',
                name_last: this.person?.name_last || '',
                job_title: this.person?.job_title || '',
                phone: this.person?.phone || '',
                email: this.person?.email || this.getContactEmail() || '',
                linkedin_link: this.person?.linkedin_link || ''
            }
        },
        components: {
            CustomInput
        },
        methods: {
            resetForm() {
                this.contact_id = '';
                this.name_first = '';
                this.name_last = '';
                this.job_title = '';
                this.phone = '';
                this.email = '';
                this.linkedin_link = '';
            },
            async addContact() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.loading = true;
                    const success = await useProspectStore().addContact({
                        ...(this.contact_id &&{contact_id: this.contact_id}),
                        name_first: this.name_first,
                        name_last: this.name_last,
                        job_title: this.job_title,
                        phone: this.phone,
                        linkedin_link: this.linkedin_link,
                        email: this.email,
                        image: this.person?.image ? this.person.image : ''
                    });
                    this.loading = false;

                    if (success) {
                        this.formSuccess = true;
                        this.formError = false;
                        this.$emit('form_submission_successful');
                        this.resetForm();
                    } else {
                        this.formSuccess = false;
                        this.formError = true;
                    }

                } else {
                    this.validateAllFields();
                }
            },
            getContactEmail() {
                const emails = this.person?.emails || null;
                if(emails) {
                    const validEmails = emails.filter(email => email.status !== 'invalid');
                    validEmails.sort((a, b) => b.confidence - a.confidence);
                    return validEmails.length > 0 ? validEmails[0].email : null;
                } else {
                    return null;
                }
            }
        }
    }
</script>
