<template>
    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-0 col-lg-5">
        <div class="content">
            <InvoiceDetails />
        </div>
    </div>
    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-0 col-lg-5">
        <div class="content">
            <SubscriptionForm :selectedSubscription="selectedSubscription" :hideSaveButton="true" :hideNonSelected="true" />
        </div>
    </div>
    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0 col-lg-10">
        <button class="btn-1 btn-m btn-block btn-checkout" @click.prevent="startPayment">{{$t('views.account_order.steps.summary.button')}}</button>
    </div>
</template>

<script>
    import SubscriptionForm from '@/components/forms/SubscriptionForm';
    import InvoiceDetails from '@/components/elements/account/InvoiceDetails';

    export default {
        name: 'AccountOrderCheckout',
        components: {
            SubscriptionForm,
            InvoiceDetails
        },
        props: {
            selectedSubscription: {
                type: [String, Boolean],
                default: false
            }
        },
        methods: {
            startPayment() {
                alert('pay now');
            }
        }
    }
</script>