import { createRouter, createWebHistory } from 'vue-router';
import {
    ifNotAuthenticatedRedirect,
    ifAuthenticatedRedirect,
    hasQueryParamPasswordSelect,
    hasAccountId,
    hasAccountAdminRights,
    isActiveAccount } from '@/router/guards';

import CompanySearchView from '@/views/account/CompanySearchView';
import CompanySearchResultView from '@/views/account/CompanySearchResultView';
import AccountsView from '@/views/AccountsView';
import ProfileView from '@/views/ProfileView';
import AccountSubscriptionsView from '@/views/account/SubscriptionsView';
import AccountOrderView from '@/views/account/OrderView';
import AccountSettingsView from '@/views/account/SettingsView';
import AccountOnboardingView from '@/views/account/OnboardingView';
import AccountUsersView from '@/views/account/UsersView';
import SuspectView from '@/views/account/SuspectView';
import ProspectsView from '@/views/account/ProspectsView';
import ProspectView from '@/views/account/ProspectView';
import LoginView from '@/views/authentication/LoginView';
import PasswordResetView from '@/views/authentication/PasswordResetView';
import PasswordSelectView from '@/views/authentication/PasswordSelectView';
import RegisterView from '@/views/authentication/RegisterView';

const routes = [
    {
        path: '/',
        name: 'home',
        component: ProspectsView,
        beforeEnter: [ifNotAuthenticatedRedirect]
    },
    {
        path: '/search',
        name: 'search',
        component: CompanySearchView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId]
    },
    {
        path: '/search/:id',
        name: 'search-result',
        component: CompanySearchResultView,
        props: true,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId]
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        component: AccountOnboardingView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
    },
    {
        path: '/settings',
        name: 'settings',
        component: AccountSettingsView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, isActiveAccount],
    },
    {
        path: '/order',
        name: 'order',
        component: AccountOrderView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, hasAccountAdminRights],
    },
    {
        path: '/subscriptions',
        name: 'subscriptions',
        component: AccountSubscriptionsView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, hasAccountAdminRights],
    },
    {
        path: '/suspect',
        name: 'suspect',
        component: SuspectView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, isActiveAccount],
    },
    {
        path: '/prospects',
        name: 'prospects',
        component: ProspectsView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
    },
    {
        path: '/prospects/:id',
        name: 'prospect',
        component: ProspectView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
        props: true
    },
    {
        path: '/users',
        name: 'users',
        component: AccountUsersView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, hasAccountAdminRights, isActiveAccount],
    },
    {
        path: '/accounts',
        name: 'accounts',
        component: AccountsView,
        beforeEnter: ifNotAuthenticatedRedirect
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfileView,
        beforeEnter: ifNotAuthenticatedRedirect
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        beforeEnter: ifAuthenticatedRedirect
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterView,
        beforeEnter: ifAuthenticatedRedirect
    },
    {
        path: '/password-reset',
        name: 'passwordReset',
        component: PasswordResetView,
        beforeEnter: ifAuthenticatedRedirect
    },
    {
        path: '/password-select',
        name: 'passwordSelect',
        component: PasswordSelectView,
        beforeEnter: [ifAuthenticatedRedirect, hasQueryParamPasswordSelect],
        props: (route) => ({ r: route.query.r })
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
