<template>
    <div class="row prospect-item" @click="openProspect">
        <div class="top">
            <div class="left">
                <div class="logo">
                    <CompanyLogo 
                        :domain="prospect.domain ? prospect.domain : null"
                        :company_name="prospect.name ? prospect.name : prospect.domain ? prospect.domain : null"
                    />
                    <LabelMini :label="prospect.label" />
                </div>
            </div>

            <div class="right">
                <div class="right-top">
                    <div class="name">{{ prospect.name }}</div>
                    <TagsMini v-if="prospect.tags && prospect.tags.length > 0" :tags="prospect.tags" />
                    <div v-else class="location">{{ prospect.city }}{{ prospect.city && prospect.country_code ? ', ' : '' }}<span>{{ prospect.country_code }}</span></div>
                </div>
                <div v-if="prospect.contacts && prospect.contacts.length > 0" class="right-middle">
                    <ContactsMini :contacts="prospect.contacts" :numberOfContacts="prospect.total_contacts" />
                </div>
            </div>
        </div>

        <div class="bottom">
            <ul class="specs-type-1">
                <li v-if="prospect.changed_time">
                    <div class="title">{{$t('components.prospects_list_item.last_change')}}</div>
                    <div class="content">{{ epochToDisplayDate(prospect.changed_time) }}</div>
                </li>

                <li v-if="prospect.owner">
                    <div class="title">{{$t('components.prospects_list_item.owner')}}</div>
                    <div class="content">{{ prospect.owner }}</div>
                </li>

                <li v-if="prospect.last_visit && prospect.total_visits">
                    <div class="title">{{$t('components.prospects_list_item.last_visit')}}</div>
                    <div class="content">{{ epochToDisplayDate(prospect.last_visit) }}</div>
                </li>
                
                <li v-if="prospect.last_visit && prospect.total_visits">
                    <div class="title">{{$t('components.prospects_list_item.number_of_visits')}}</div>
                    <div class="content">{{ prospect.total_visits }}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CompanyLogo from '@/components/elements/misc/CompanyLogo';
import ContactsMini from '@/components/elements/prospects/ContactsMini';
import LabelMini from '@/components/elements/misc/LabelMini';
import TagsMini from '@/components/elements/prospects/TagsMini';
import { useProspectsStore } from '@/stores/prospects';

export default {
    name: 'ProspectsListItem',
    components: {
        CompanyLogo,
        ContactsMini,
        LabelMini,
        TagsMini
    },
    props: {
        prospect: {
            type: Object,
            required: true
        }
    },
    computed: {
        prospectsStore() {
            return useProspectsStore();
        }
    },
    methods: {
        openProspect() {
            this.prospectsStore.openProspect(this.prospect);
        },
        epochToDisplayDate(epoch) {
            const date = new Date(epoch * 1000);
            const today = new Date();
            const isToday = today.getFullYear() === date.getFullYear() &&
                            today.getMonth() === date.getMonth() &&
                            today.getDate() === date.getDate();
            if (isToday) {
                const timeOptions = { hour: 'numeric', minute: 'numeric' };
                return date.toLocaleTimeString('nl-NL', timeOptions);
            } else {
                const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
                return date.toLocaleDateString('nl-NL', dateOptions);
            }
        }
    }
}
</script>