<template>
    <div class="section">
        <ul class="specs">

            <li v-for="(value, key) in filteredData" :key="key" class="prospect-ai-helper" :class="[isObject(value) ? 'parent' : '']">
                <div class="title">{{ $te('components.prospect_ai.fields.' + key) ? $t('components.prospect_ai.fields.' + key) : formatKey(key) }}</div>
                <div class="content">
                    <ul v-if="Array.isArray(value) && value.length > 1" class="list">
                        <li v-for="(item, index) in value" :key="index">
                            <ProspectAiHelper v-if="isObject(item)" :data="item" />
                            <span v-else>{{ item }}</span>
                        </li>
                    </ul>
                    <div v-else-if="Array.isArray(value) && value.length === 1" class="content">
                        {{ value[0] }}
                    </div>
                    <ProspectAiHelper v-else-if="isObject(value)" :data="value" />
                    <span v-else>{{ value }}</span>
                </div>
            </li>

        </ul>
    </div>
</template>

<script>
    export default {
        name: "ProspectAiHelper",
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        methods: {
            formatKey(key) {
                return key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
            },
            isObject(value) {
                return value && typeof value === "object" && !Array.isArray(value);
            },
            hasValue(value) {
                if (Array.isArray(value)) {
                    return value.length > 0;
                }
                return value !== null && value !== undefined && value !== '';
            }
        },
        computed: {
            filteredData() {
                return Object.keys(this.data)
                    .filter((key) => this.hasValue(this.data[key]))
                    .reduce((obj, key) => {
                        obj[key] = this.data[key];
                    return obj;
                }, {});
            }
        }
    };
</script>