import { defineStore } from 'pinia';
import { downloadProspects, fetchProspects } from "@/services/ppApi";
import { useAccountStore } from '@/stores/account';
import { useUserStore } from '@/stores/user';
import router from '@/router';
import { dateOptionToEpoch } from '@/utils/dates';

export const useProspectsStore = defineStore('prospects', {
    state: () => {
        const accountStore = useAccountStore();
        const accountId = accountStore?.account?.account_id ? accountStore.account.account_id : null;
        const userProspectsSettings = useUserStore().getProspectsSettings(accountId);

        const defaultOptions = {
            search: '',
            sort: 'default',
            label: 'all',
            tags: [],
            created: 'all',
            updated: 'all',
            owner: 'all',
            contacts: 'all',
            visits: 'all'
        };
        const defaultPagination = {
            limit: '20',
            page: 1
        };
        return {
            prospects: [],
            updatingOptions: false,
            downloadInProgress: false,
            downloadLinks: {},
            loading: false,
            error: null,
            found: 0,
            total: 0,
            defaultOptions: defaultOptions,
            options: userProspectsSettings?.options ? { ...defaultOptions, ...userProspectsSettings.options } : { ...defaultOptions },
            pagination: { ...defaultPagination },
            currentProspectIndex: null,
        }
    },
    getters: {
        hasDefaultOptions(state) {
            return JSON.stringify(state.options) === JSON.stringify(state.defaultOptions);
        },
        isFirstProspect(state) {
            return state.currentProspectIndex === 0 && state.pagination.page === 1;
        },
        isLastProspect(state) {
            const isLastItemOnPage = state.currentProspectIndex === state.prospects.length - 1;
            const isLastPage = state.pagination.page * state.pagination.limit >= state.total;
            return isLastItemOnPage && isLastPage;
        }
    },
    actions: {
        resetProspects() {
            this.$reset();
        },
        async loadProspects() {
            this.loading = true;
            this.error = null;

            try {
                const params = this.generateOptionParams();
                const response = await fetchProspects(params);
                this.prospects = response.data.companies;
                this.found = response.data.found;
                this.total = this.found === 0 ? 0 : response.data.total || this.total;
            } catch (error) {
                this.prospects = [];
                this.found = 0;
                this.total = 0;
                this.error = error;
            } finally {
                this.loading = false;
                this.updatingOptions = false;
            }
        },
        async downloadProspects() {
            this.downloadInProgress = true;

            try {
                let params = this.generateOptionParams();
                    delete params.page;
                    delete params.limit;
                    delete params.add_details;
                    delete params.add_fields;
                    params.add_contacts = 10;
                    params.export = 1;
                    params.sort_by = 'default';

                const response = await downloadProspects(params);
                if(response?.data?.export_url_csv || response?.data?.export_url_xlsx || response?.data?.export_url_zip) {
                    this.downloadLinks = {
                        export_url_csv: response.data.export_url_csv || null,
                        export_url_xlsx: response.data.export_url_xlsx || null,
                        export_url_zip: response.data.export_url_zip || null
                    };
                    return true;
                } else {
                    this.downloadLinks = {};
                    return false;
                }
            } catch {
                this.downloadLinks = {};
                return false;
            } finally {
                this.downloadInProgress = false;
            }
        },
        generateOptionParams() {
            const accountStore = useAccountStore();
            const createdDate = dateOptionToEpoch(this.options.created);
            const updatedDate = dateOptionToEpoch(this.options.updated);

            return {
                add_contacts: 3,
                account_id: accountStore.account.account_id,
                add_fields: 'owner',
                // add_details: '*',
                limit: this.pagination.limit,
                page: this.pagination.page,
                ...(this.options.search && { text: this.options.search }),
                ...(this.options.sort && !this.options.sort.includes('-') && !this.options.sort.includes('default') && { sort_by: this.options.sort }),
                ...(this.options.sort && this.options.sort.includes('-') && { sort_by: this.options.sort.split('-')[0] }),
                ...(this.options.sort && this.options.sort.includes('-') && { sort_order: this.options.sort.split('-')[1] }),
                ...(this.options.label && this.options.label != 'all' && { label: this.options.label }),
                ...(createdDate && { from_inserted_date: createdDate }),
                ...(updatedDate && { from_changed_time: updatedDate }),
                ...(this.options.tags.length > 0 && { tags: this.options.tags.join(',') }),
                ...(this.options.owner && this.options.owner != 'all' && { owner: this.options.owner }),
                ...(this.options.contacts && this.options.contacts != 'all' && { total_contacts: this.options.contacts }),
                ...(this.options.visits && this.options.visits != 'all' && { total_visits: this.options.visits })
            };
        },
        updateOptions(newOptions) {
            this.options = { ...this.options, ...newOptions };
            this.saveProspectsSettings();
            this.pagination.page = 1;
            this.downloadLinks = {};
            this.loadProspects();
        },
        saveProspectsSettings() {
            const userStore = useUserStore();
            const accountStore = useAccountStore();
            userStore.saveProspectsSettings(accountStore.account.account_id, { options: this.options });
        },
        setUpdatingOptions(updating) {
            this.updatingOptions = updating;
        },
        resetOptions() {
            this.options = { ...this.defaultOptions };
            this.saveProspectsSettings();
            this.pagination.page = 1;
            this.downloadLinks = {};
            this.loadProspects();
        },
        updatePagination(newPagination) {
            this.pagination = { ...this.pagination, ...newPagination };
            this.loadProspects();
        },
        async loadNextProspect() {
            if (this.currentProspectIndex === null) return;
            
            this.currentProspectIndex += 1;
            
            if (this.currentProspectIndex >= this.prospects.length) {
                this.pagination.page += 1;
                await this.loadProspects();
                this.currentProspectIndex = 0;
            }
    
            const nextProspectId = this.prospects[this.currentProspectIndex]?.id;

            if (nextProspectId) {
                router.push({ name: 'prospect', params: { id: nextProspectId } });
            }
        },
        async loadPreviousProspect() {
            if (this.currentProspectIndex === null) return;
            
            this.currentProspectIndex -= 1;
    
            if (this.currentProspectIndex < 0) {
                if (this.pagination.page > 1) {
                    this.pagination.page -= 1;
                    await this.loadProspects();
                    this.currentProspectIndex = this.prospects.length - 1;
                } else {
                    this.currentProspectIndex = 0;
                }
            }
    
            const prevProspectId = this.prospects[this.currentProspectIndex]?.id;
            if (prevProspectId) {
                router.push({ name: 'prospect', params: { id: prevProspectId } });
            }
        },
        openProspect(prospect) {
            const index = this.prospects.indexOf(prospect);
            this.currentProspectIndex = index;
            router.push({ name: 'prospect', params: { id: prospect.id } });
        },
    }
});
