<template>
   <div class="container-fluid options-type-2">
        <div class="container" ref="container">
            <slot v-if="$slots['options-default']" name="options-default"></slot>
            <slot v-if="$slots['options-counter']" name="options-counter"></slot>
            <slot v-if="$slots['options-no-results']" name="options-no-results"></slot>
            <slot v-if="fullOptions && $slots['options-full']" name="options-full"></slot>
            <div class="toggle-button" @click="this.toggleFullOptions()">
                <div v-if="!fullOptions" class="icon-plus-pos"></div>
                <div v-if="fullOptions" class="icon-close-pos"></div>
                <span v-if="!fullOptions">{{ $t('components.suspect_options.expand') }}</span>
                <span v-if="fullOptions">{{ $t('components.suspect_options.minimize') }}</span>
                <span v-if="count" class="count">{{count}}</span>
            </div>
        </div>   
    </div>
</template>

<script>
export default {
    name: "OptionsSuspectPanel",
    props: {
        count: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            fullOptions: false
        }
    },
    methods: {
        setContainerHeight() {
            const container = this.$refs.container;
            if (container) {
                container.style.maxHeight = window.innerWidth >= 769 ? `${window.innerHeight - 0}px` : `${window.innerHeight - 64}px`;
            }
        },
        toggleFullOptions(show = null, event = null) {
            if (event) {
                event.stopPropagation(); // Prevent timing issues!
            }
            this.fullOptions = show !== null ? show : !this.fullOptions;
        },
    },
    mounted() {
        this.setContainerHeight();
        window.addEventListener('resize', this.setContainerHeight);
    },
    unmounted() {
        this.toggleFullOptions(false);
        window.removeEventListener('resize', this.setContainerHeight);
    }
}
</script>