<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <div class="container-fluid content-type-3">
            <div class="container">
                <SettingsTitle />
                <AccountDetailsSection />
                <AccountTrackerSettings :account="account" />
                <AccountApiSettings :account="account" />
            </div>
        </div>
    </AccountViewBase>
</template>

<script>
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import SettingsTitle from '@/components/account/settings/SettingsTitle';
    import AccountDetailsSection from '@/components/account/settings/AccountDetailsSettings';
    import AccountTrackerSettings from '@/components/account/settings/AccountTrackerSettings';
    import AccountApiSettings from '@/components/account/settings/AccountApiSettings';
    import { scrollToTop } from '@/utils/scroll';

    export default {
        name: 'AccountSettingsView',
        components: {
            AccountViewBase,
            SettingsTitle,
            AccountDetailsSection,
            AccountTrackerSettings,
            AccountApiSettings
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.account_settings.seo_title',
            };
        },
        mounted() {
            scrollToTop();
        }
    }
</script>
