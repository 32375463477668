<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="true"
        :toggleable="this.paywallActive"
        :paywall="paywall"
        :defaultToggle="this.paywallActive ? false : true"
        v-if="!hide"
        loadingText="components.prospect_pageviews.loading_text"
        cssClass="content-type-9"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_tags.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="tags">
                    <CustomCheckboxTagsAdd
                        :modelValue="tags"
                        @update:modelValue="onTagsChanged"
                        :showLabel="false"
                        cssClass="custom-checkbox-type-5"
                    />
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { useUserStore } from '@/stores/user';
    import { useAccountStore } from '@/stores/account';
    import CustomCheckboxTagsAdd from '@/components/elements/inputs/CustomCheckboxTagsAdd';
    import WidgetSection from '@/components/sections/WidgetSection';
    import i18n from "@/plugins/i18n";
    import { useToastStore } from '@/stores/toast';

    export default {
        name: "ProspectTags",
        components: {
            WidgetSection,
            CustomCheckboxTagsAdd,
        },
        props: {
            defaultToggle: {
                type: Boolean,
                default: false
            },
            paywall: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            },
            company() {
                return useProspectStore().prospect;
            },
            tags() {
                return this.company?.tags && this.company.tags != '' ? this.company.tags.split('|') : [];
            },
            hide() {
                return !this.company || !this.isAuthenticated;
            },
            isAuthenticated() {
                return useUserStore().isAuthenticated;
            },
        },
        methods: {
            onTagsChanged(tags) {
                const result = useProspectStore().updateTags(tags);

                if(result) {
                    useToastStore().addToast(i18n.global.t('toasts.success.tags_updated'), 'success');
                } else {
                    useToastStore().addToast(i18n.global.t('toasts.error.tags_not_updated'), 'error');
                }
            }
        }
    }
</script>