<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        v-if="!hide"
        :defaultToggle="defaultToggle"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_industries.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="section">
                    <ul v-if="industries" class="specs">
                        <li v-if="sbi" class="small-screen-fw">
                            <div class="title">
                                {{ $t('common.industry')}}
                            </div>
                            <ul v-if="sbi.length > 1" class="industries">
                                <li v-for="(industry, index) in sbi" :key="index">{{ industry }}</li>
                            </ul>
                            <div class="content" v-else>
                                {{ sbi[0] }}
                            </div>
                        </li>
                        <li v-if="nace" class="small-screen-fw">
                            <div class="title">
                                {{ $t('common.nace_industry')}}
                            </div>
                            <ul v-if="nace.length > 1" class="industries">
                                <li v-for="(industry, index) in nace" :key="index">{{ industry }}</li>
                            </ul>
                            <div class="content" v-else>
                                {{ nace[0] }}
                            </div>
                        </li>
                        <li v-if="naics" class="small-screen-fw">
                            <div class="title">
                                {{ $t('common.naics_industry')}}
                            </div>
                            <ul v-if="naics.length > 1" class="industries">
                                <li v-for="(industry, index) in naics" :key="index">{{ industry }}</li>
                            </ul>
                            <div class="content" v-else>
                                {{ naics[0] }}
                            </div>
                        </li>
                    </ul> 
                    <ul v-if="industries" class="specs">
                        <li v-if="google">
                            <div class="title">
                                {{ $t('common.google_industry')}}
                            </div>
                            <ul v-if="google.length > 1" class="industries">
                                <li v-for="(industry, index) in google" :key="index">{{ industry }}</li>
                            </ul>
                            <div class="content" v-else>
                                {{ google[0] }}
                            </div>
                        </li>
                        <li v-if="industries.linkedin_industry">
                            <div class="title">
                                {{ $t('common.linkedin_industry')}}
                            </div>
                            <ul v-if="typeof industries.linkedin_industry == 'object'" class="industries">
                                <li v-for="(industry, index) in industries.linkedin_industry" :key="index">{{ industry }}</li>
                            </ul>
                            <div class="content" v-else>
                                {{ industries.linkedin_industry }}
                            </div>
                        </li>
                    </ul>           
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import i18n from "@/plugins/i18n";
    import WidgetSection from '@/components/sections/WidgetSection';

    export default {
        name: "ProspectIndustries",
        components: {
            WidgetSection
        },
        props: {
            defaultToggle: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                loaded: true
            }
        },
        computed: {
            industries() {
                return useProspectStore().industries;
            },
            hide() {
                return !this.industries;
            },
            sbi() {
                if(!this.industries?.sbi_sec || !this.industries?.sbi_sec_category) {
                    return false;
                } else {
                    if(
                        this.industries.sbi_sec &&
                        typeof this.industries.sbi_sec === 'object' &&
                        typeof this.industries.sbi_sec_category === 'object' &&
                        this.industries.sbi_sec.length === this.industries.sbi_sec_category.length
                    ) {
                        return this.industries.sbi_sec_category.map((category, index) => {
                            return `${category} (SBI: ${this.industries.sbi_sec[index]})`;
                        });
                    } else {
                        return this.industries.sbi_sec_category;
                    }
                }
            },
            google() {
                if(!this.industries?.google_category_nl && !this.industries?.google_category_en) {
                    return false;
                } else if (this.industries?.google_category_nl && this.industries?.google_category_en && (i18n.global.locale === 'nl' || i18n.global.locale === 'en')) {
                    return this.industries['google_category_' + i18n.global.locale];
                } else {
                    return this.industries.google_category_nl ? this.industries?.google_category_nl : this.industries?.google_category_en;
                }
            },
            nace() {
                if(!this.industries?.nace_category_nl && !this.industries?.nace_category_en) {
                    return false;
                } else if (this.industries?.nace_category_nl && this.industries?.nace_category_en && (i18n.global.locale === 'nl' || i18n.global.locale === 'en')) {
                    if(
                        typeof this.industries['nace_category_' + i18n.global.locale] === 'object' &&
                        this.industries.nace &&
                        typeof this.industries.nace === 'object' &&
                        this.industries['nace_category_' + i18n.global.locale].length === this.industries.nace.length
                    ) {
                        return this.industries['nace_category_' + i18n.global.locale].map((category, index) => {
                            return `${category} (NACE: ${this.industries.nace[index]})`;
                        });
                    } else {
                        return this.industries['nace_category_' + i18n.global.locale];
                    }
                } else {
                    const industries = this.industries.nace_category_nl ? this.industries?.nace_category_nl : this.industries?.nace_category_en;
                    if(
                        this.industries.nace &&
                        typeof this.industries.nace === 'object' &&
                        typeof industries === 'object' &&
                        industries.length === this.industries.nace.length
                    ) {
                        return industries.map((category, index) => {
                            return `${category} (NACE: ${this.industries.nace[index]})`;
                        });
                    } else {
                        return industries;
                    }
                }
            },
            naics() {
                if(!this.industries?.naics_category) {
                    return false;
                } else {
                    if(
                        this.industries.naics &&
                        typeof this.industries.naics === 'object' &&
                        typeof this.industries.naics_category === 'object' &&
                        this.industries.naics.length === this.industries.naics_category.length
                    ) {
                        return this.industries.naics_category.map((category, index) => {
                            return `${category} (NAICS: ${this.industries.naics[index]})`;
                        });
                    } else {
                        return this.industries.sbi_sec_category;
                    }
                }
            }
        }
    }
</script>