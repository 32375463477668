<template>
    <div :class="['container-fluid', 'content-type-5', 'suspect', cssClass]">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div :class="['btn-s-icon', 'suspect-previous', history && history.length > 1 ? 'btn-5' : 'btn-disabled']" @click="previousSuspect" v-if="showSuspectActions">
                            <div class="icon-chevrons-pos"></div>
                        </div>
                        <div class="btn-7 btn-m-icon suspect-qualify" @click="qualifySuspect(1)" v-if="showSuspectActions">
                            <div class="icon-check-neg"></div>
                        </div>
                        <div class="btn-1 btn-m-icon suspect-settings" @click="toggleSettings" v-if="!showSuspectOptions && !loading">
                            <div class="icon-settings-neg"></div>
                        </div>
                        <div class="btn-6 btn-m-icon suspect-disqualify" @click="qualifySuspect(0)" v-if="showSuspectActions">
                            <div class="icon-close-neg"></div>
                        </div>
                        <div class="btn-5 btn-s-icon suspect-skip" @click="qualifySuspect(-1)" v-if="showSuspectActions">
                            <div class="icon-chevrons-pos"></div>
                        </div>

                        <div class="loading-spinner suspect-settings" v-if="loading">
                            <div class="loading-spinner-type-2"></div>
                        </div>

                        <div class="btn-1 btn-m-icon suspect-settings" @click="toggleSettings" v-if="!loading && prospect && showSuspectOptions">
                            <div class="icon-close-neg"></div>
                        </div>
                        <div class="btn-m-icon suspect-settings" :class="[optionsChanged ? 'btn-7' : 'btn-disabled']" @click="saveSettings" v-if="!loading && showSuspectOptions">
                            <div class="icon-search-neg"></div>
                        </div>
                        <div class="btn-m-icon suspect-settings" :class="[!hasDefaultOptions ? 'btn-6' : 'btn-disabled']" @click="resetSettings" v-if="!loading && showSuspectOptions">
                            <div class="icon-reset-neg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-type-5-spacer suspect"></div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'pinia';
    import { useSuspectStore } from '@/stores/suspect';
    import { useProspectStore } from '@/stores/prospect';

    export default {
        name: "SuspectActions",
        emits: ['toggle-options'],
        props: {
            cssClass: {
                type: String,
                required: false
            },
            showSuspectOptions: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState(useSuspectStore, ['history', 'loading', 'error', 'optionsChanged']),
            ...mapGetters(useSuspectStore, ['hasDefaultOptions']),
            ...mapState(useProspectStore, ['prospect']),
            showSuspectActions() {
                return !this.loading && !this.error && !this.showSuspectOptions && this.prospect;
            }
        },
        methods: {
            ...mapActions(useSuspectStore, ['resetOptions', 'fetchSuspect', 'qualifySuspect', 'previousSuspect']),
            toggleSettings() {
                this.$emit('toggle-options');
            },
            saveSettings() {
                if( this.optionsChanged ) {
                    this.fetchSuspect();
                }
                this.toggleSettings();
            },
            resetSettings() {
                this.resetOptions();
            }
        }
    }
</script>