<template>
    <ToggleSimple>
        <template #toggle>
            <div class="left">
                <div v-if="$te(`fields.${name}.label`)" class="toggle-text">
                    {{ $t(`fields.${name}.label`) }}

                    <span v-if="isActive">
                        <div class="icon-check-dark"></div>
                    </span>
                </div>
            </div>
            <div class="right">
                <div v-if="isActive" class="reset" @click="resetSection">
                    <div class="icon-reset-pos"></div>
                </div>
                <div class="toggle-icon"></div>
            </div>
        </template>

        <template #content>
            <CustomRange
                inputKey="founded"
                :modelValue="founded"
                :min="min"
                :max="max"
                :showLabel="false"
                :showLabelMinMax="true"
                @update:modelValue="onRangeChanged"
            />
        </template>
    </ToggleSimple>
</template>

<script>
    import CustomRange from '@/components/elements/inputs/CustomRange';
    import ToggleSimple from '@/components/elements/misc/ToggleSimple';

    export default {
        name: "OptionsFounded",
        components: {
            ToggleSimple,
            CustomRange
        },
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            cssClass: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                name: 'founded',
                min: 1500,
                max: new Date().getFullYear(),
                founded: {
                    min: this.modelValue.includes(':') ? parseInt(this.modelValue.split(':')[0]) : this.min,
                    max: this.modelValue.includes(':') ? parseInt(this.modelValue.split(':')[1]) : this.max
                }
            };
        },
        computed: {
            isActive() {
                return this.modelValue !== '';
            },
        },
        methods: {
            resetSection(event = null) {
                if (event) {
                    event.stopPropagation()
                }
                this.founded = { min: '', max: '' };
                this.$emit('update:modelValue', '');
            },
            // onChanged(key, value) {
            //     this.founded[key] = value;
            //     const { min, max } = this.founded;
            //     let founded = '';
                
            //     if(min && min !== '' && max && max !== '') {
            //         founded = `${min}:${max}`;
            //     } else if(min && min !== '') {
            //         founded = `${min}:${ new Date().getFullYear() }`;
            //     } else if(max && max !== '') {
            //         founded = `1500:${max}`;
            //     }
                
            //     this.$emit('update:modelValue', founded);
            // },
            onRangeChanged(value) {
                var { min, max } = value;
                let founded = '';
                
                if(min && min !== '' && max && max !== '') {
                    founded = `${min}:${max}`;
                } else if(min && min !== '') {
                    founded = `${min}:${this.max}`;
                } else if(max && max !== '') {
                    founded = `${this.min}:${max}`;
                }
                this.$emit('update:modelValue', founded);
            }
        },
        watch: {
            modelValue(newVal) {
                const [min, max] = newVal.split(':').map(Number);
                this.founded = { min, max };
            }
        },
    }
</script>