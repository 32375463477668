<template>
    <OptionsPanel ref="optionsPanel">
        <template #options-results v-if="total || total === 0">
            {{ 
                total === 1 ?
                    $t('views.prospects.results_count.s', { found: total }) :
                    $t('views.prospects.results_count.p', { found: total })
            }}
        </template>

        <template #options-default>
            <div class="form-type-1">
                <CustomInput
                    v-model="options.search"
                    inputKey="prospects_search"
                    @update:modelValue="onChangedDebounced"
                />
                <CustomSelect
                    v-model="options.sort"
                    inputKey="prospects_sort"
                    @update:modelValue="onChanged"
                    :options="sortOptions"
                />
                <CustomSelect
                    v-model="options.label"
                    inputKey="prospects_filter_label"
                    @update:modelValue="onChanged"
                    :options="filterLabelOptions"
                />
            </div>
        </template>

        <template #options-full>
            <div class="form-type-1">
                <CustomCheckboxTagsFilter
                    v-model="options.tags"
                    @update:modelValue="onChanged"
                />
                <CustomSelectTimePeriod
                    v-model="options.created"
                    @update:modelValue="onChanged"
                    inputKey="prospects_filter_created"
                />
                <CustomSelectTimePeriod
                    v-model="options.updated"
                    @update:modelValue="onChanged"
                    inputKey="prospects_filter_updated"
                />
                <CustomRadio
                    v-model="options.contacts"
                    inputKey="prospects_filter_contacts"
                    :options="contactsOptions"
                    @update:modelValue="onChanged"
                    cssClass="custom-radio-type-2"
                />
                <CustomRadio
                    v-model="options.visits"
                    inputKey="prospects_filter_visits"
                    :options="visitsOptions"
                    @update:modelValue="onChanged"
                    cssClass="custom-radio-type-2"
                />
                <CustomSelectOwner
                    v-model="options.owner"
                    @update:modelValue="onChanged"
                />
            </div>
        </template>

        <template #actions>
            <div :class="hasDefaultOptions ? 'btn-disabled' : 'btn-6'" @click="onReset()">
                <div class="icon-reset-neg"></div>
                <span>{{ $t('common.reset_1') }}</span>
            </div>
            <button :class="[total > 0 && !paywall ? 'btn-2' : 'btn-disabled', paywall ? 'paywall' : '']" :disabled="total < 1 || paywall" @click="confirmDownloadPopup()">
                <div class="icon-download-neg"></div>
                <span>{{ $t('common.download') }}</span>
                <div v-if="paywall" class="paywall-lock"></div>
            </button>
        </template>
    </OptionsPanel>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { useUserStore } from '@/stores/user';
    import { useAccountStore } from '@/stores/account';
    import { usePopupStore } from '@/stores/popup';
    import { useProspectsStore } from '@/stores/prospects';
    import { useScreenWidthStore } from '@/stores/utils/screenWidth';
    import { mapState, mapActions, mapGetters } from 'pinia';
    import OptionsPanel from '@/components/panels/options/OptionsPanel';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import CustomSelect from '@/components/elements/inputs/CustomSelect';
    import CustomRadio from '@/components/elements/inputs/CustomRadio';
    import CustomCheckboxTagsFilter from '@/components/elements/inputs/CustomCheckboxTagsFilter';
    import CustomSelectOwner from '@/components/elements/inputs/CustomSelectOwner';
    import CustomSelectTimePeriod from '@/components/elements/inputs/CustomSelectTimePeriod';

    export default {
        name: "ProspectsOptions",
        components: {
            OptionsPanel,
            CustomInput,
            CustomSelect,
            CustomRadio,
            CustomCheckboxTagsFilter,
            CustomSelectOwner,
            CustomSelectTimePeriod
        },
        data() {
            return {
                sortOptions: [
                    { value: 'default', text: 'fields.prospects_sort.option_default' },
                    { value: 'company_name-asc', text: 'fields.prospects_sort.option_name_asc' },
                    { value: 'company_name-desc', text: 'fields.prospects_sort.option_name_desc' },
                    { value: 'changed_time-asc', text: 'fields.prospects_sort.option_changed_date_asc' },
                    { value: 'changed_time-desc', text: 'fields.prospects_sort.option_changed_date_desc' },
                    { value: 'inserted_date-asc', text: 'fields.prospects_sort.option_creation_date_asc' },
                    { value: 'inserted_date-desc', text: 'fields.prospects_sort.option_creation_date_desc' },
                ],
                filterLabelOptions: [
                    { value: 'all', text: 'fields.prospects_filter_label.option_all' },
                    { value: '1', text: 'fields.prospects_filter_label.option_1' },
                    { value: '0', text: 'fields.prospects_filter_label.option_0' },
                    { value: '-1', text: 'fields.prospects_filter_label.option_-1' },
                ],
                contactsOptions: [
                    { value: 'all', text: 'fields.prospects_filter_contacts.option_all' },
                    { value: '1', text: 'fields.prospects_filter_contacts.option_1' },
                    { value: '0:0', text: 'fields.prospects_filter_contacts.option_0' },
                ],
                visitsOptions: [
                    { value: 'all', text: 'fields.prospects_filter_visits.option_all' },
                    { value: '1', text: 'fields.prospects_filter_visits.option_1' },
                    { value: '0:0', text: 'fields.prospects_filter_visits.option_0' },
                ],
                miscOptions: [
                    { value: 'visits', text: 'fields.prospects_filter_misc.has_visits' },
                    { value: 'contacts', text: 'fields.prospects_filter_misc.has_contacts' }
                ]
            }
        },
        computed: {
            ...mapState(useProspectsStore, ['options', 'total', 'downloadLinks']),
            ...mapState(useAccountStore, ['account']),
            ...mapGetters(useProspectsStore, ['hasDefaultOptions']),
            screenWidthStore() {
                return useScreenWidthStore();
            },
            hasLargeScreen() {
                return this.screenWidthStore.width >= 992;
            },
            userStore() {
                return useUserStore();
            },
            accountStore() {
                return useAccountStore();
            },
            paywall() {
                return !this.accountStore.isActive && !this.userStore.isPartnerAdmin;
            }
        },
        methods: {
            ...mapActions(useProspectsStore, ['updateOptions', 'setUpdatingOptions', 'resetOptions', 'downloadProspects']),
            onChanged() {
                this.updateOptions(this.options);
            },
            onChangedDebounced(value) {
                this.setUpdatingOptions(true);
                this.options.search = value;
                this.updateDebounced();
            },
            updateDebounced: debounce(function() {
                this.updateOptions(this.options);
            }, 500),
            onReset() {
                this.resetOptions();
                this.$refs.optionsPanel.toggleOptions(false);
                this.$refs.optionsPanel.toggleFullOptions(false);
            },
            async fetchDownloadUrls() {
                if(!this.loading) {
                    const downloadReady = await this.downloadProspects();
                    if(downloadReady) {
                        return true;
                    }
                    return false;
                }
            },
            startDownload() {
                if(this.downloadLinks && Object.keys(this.downloadLinks).length > 0) {
                    const url = this.downloadLinks.export_url_zip || this.downloadLinks.export_url_csv || this.downloadLinks.export_url_xlsx;
                    window.open(url, "_blank"); 
                }
            },
            confirmDownloadPopup() {
                usePopupStore().showPopup('fetch', {
                    title: 'popups.confirm_download.title',
                    description: 'popups.confirm_download.description',
                    btnConfirm: "popups.confirm_download.button_confirm",
                    btnCancel: "common.cancel_1",
                    fetchFunction: this.fetchDownloadUrls,
                    onFetchSuccess: this.startDownloadPopup
                });
            },
            startDownloadPopup() {
                usePopupStore().showPopup('confirm', {
                    title: 'popups.start_download.title',
                    description: 'popups.start_download.description',
                    btnConfirm: "popups.start_download.button_confirm",
                    btnCancel: "common.cancel_1",
                    confirmFunction: this.startDownload,
                });
            },
        }
    }
</script>