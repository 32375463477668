<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        v-if="!hide"
        :defaultToggle="defaultToggle"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_opening_hours.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">  
                <ul v-if="openingHours?.length > 0" class="opening-hours-type-1">
                    <li v-for="(openingHour, index) in openingHours" :key="index" :class="[openingHour.important ? 'current-day' : '']">
                        <div class="day">
                            {{ $t(`components.opening_hours.options.${openingHour.day_nr}.${textType}`) }}
                        </div>
                        <div class="time">
                            {{ openingHour.open }} - {{ openingHour.closed }}
                        </div>
                    </li>
                </ul>       
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';
    
    export default {
        name: "ProspectOpeningHours",
        components: {
            WidgetSection
        },
        props: {
            defaultToggle: {
                type: Boolean,
                default: false
            },
            textType: {
                type: String,
                default: 'long'
            }
        },
        data() {
            return {
                loaded: true
            }
        },
        computed: {
            openingHours() {
                return useProspectStore().openingHours;
            },
            hide() {
                return !this.openingHours || this.openingHours?.length < 1;
            }
        }
    }
</script>