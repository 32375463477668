<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <ProspectsOptions />
        <ProspectsList />
    </AccountViewBase>
</template>

<script>
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import ProspectsOptions from '@/components/account/prospects/ProspectsOptions';
    import ProspectsList from '@/components/account/prospects/ProspectsList';

    export default {
        name: 'ProspectsView',
        components: {
            AccountViewBase,
            ProspectsOptions,
            ProspectsList
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.prospects.seo_title'
            };
        }
    }
</script>
