<template>
    <div class="nav-type-1-spacer"></div>
    <div class="container-fluid nav-type-1">
        <div class="container">
            <div class="nav-left">
                <router-link :to="{ name: 'suspect' }">
                    <div class="nav-logo logo-pos"></div>
                </router-link>
            </div>
            <div class="nav-right">
                <ProfileNav />
            </div>
        </div>
    </div>
</template>

<script>
    import ProfileNav from '@/components/navigation/ProfileNav';

    export default {
        name: 'TopNavAccounts',
        components: {
            ProfileNav
        },
    }
</script>
