import { defineStore } from 'pinia';
import { fetchCompanies } from '@/services/ppApi';
import { useProspectStore } from '@/stores/prospect';
import { useAccountStore } from '@/stores/account';
import { useUserStore } from '@/stores/user';

export const useCompanyStore = defineStore('company', {
    state: () => {
        return {
            loading: false,
            error: null
        }
    },
    actions: {
        async fetchCompany(id, router = null) {
            this.loading = true;

            try {
                const accountStore = useAccountStore();
                const account_id = accountStore.account.account_id; // Set default for authenticated users.

                const response = await fetchCompanies({
                    id,
                    account_id,
                    add_details: '*',
                    suspectsformat: 1,
                    add_fields: 'owner'
                });

                if(response.data.found && response.data.found > 0) {
                    useProspectStore().setProspect(response.data.companies[0]);
                    useUserStore().addToSearchHistory(response.data.companies[0]);
                } else {
                    useProspectStore().resetProspect();
                    
                    if(router) {
                        router.push({ name: 'home'});
                    }
                }
            } catch(error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        resetCompany() {
            this.$reset();
        }
    }
});