<template>
    <div :class="[account?.onboarding_status === 0 || account?.onboarding_status > 1 ? 'onboarding' : 'account', { loading, error: !!error }]">
        <slot v-if="!loading && !error && account"></slot>

        <div v-if="loading" class="loading-section">
            <div class="loading-spinner"></div>
            <div class="loading-text">{{ $t('common.loading_1') }}</div>
        </div>

        <div v-if="error" class="error-section">
            <div class="error-message">{{ error.message }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccountViewBase',
        props: {
            account: {
                type: Object,
                required: false
            },
            loading: {
                type: Boolean,
                required: true
            },
            error: {
                type: Object,
                required: false
            }
        },
        watch: {
            error(error) {
                if(error.response && error.response.status === 403 && (!error.response.data.error_code || error.response.data.error_code !== 'no_data')) {
                    this.$router.push({name: 'accounts'});
                }
            }
        }
    }
</script>
