<template>
    <CustomRadio
        :modelValue="label"
        inputKey="label"
        @update:modelValue="onLabelChanged"
        :options="optionsLabel"
        :showLabel="false"
        cssClass="custom-radio-type-3"
        :paywallActive="paywallActive"
    />
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { useAccountStore } from '@/stores/account';
    import CustomRadio from '@/components/elements/inputs/CustomRadio';
    import i18n from "@/plugins/i18n";
    import { useToastStore } from '@/stores/toast';

    export default {
        name: "ProspectLabel",
        components: {
            CustomRadio
        },
        props: {
            paywall: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                optionsLabel: [
                    { value: 1, text: 'fields.label.options.1' },
                    { value: -1, text: 'fields.label.options.-1' },
                    { value: 0, text: 'fields.label.options.0' },
                ]
            }
        },
        computed: {
            company() {
                return useProspectStore().prospect;
            },
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            },
            label() {
                return this.company?.label || this.company?.label === 0 ? this.company.label : '';
            },
        },
        methods: {
            onLabelChanged(label) {
                if(this.paywallActive) { return; }
                
                const result = useProspectStore().updateLabel(label);

                if(result) {
                    useToastStore().addToast(i18n.global.t('toasts.success.label_updated'), 'success');
                } else {
                    useToastStore().addToast(i18n.global.t('toasts.error.label_not_updated'), 'error');
                }
            }
        }
    }
</script>