<template>
    <template v-if="!loading && !error && found > 0">
        <div class="container-fluid item-lists-type-2">
            <div class="container">
                <UsersListItem
                    v-for="user in users"
                    :key="user.uid"
                    :user="user"
                />
            </div>
        </div>
    </template>
    <NoResultsSection
        v-else-if="!loading && !error"
        :message="options.search ? 'views.prospects.no_results_search' : ''"
        :translationPlaceholders="options.search ? { query: `<span>${options.search}</span>` } : {}"
    />
    <ErrorSection v-else-if="error" :error="error" />
    <LoadingSection v-else />
</template>

<script>
    import { mapState } from 'pinia';
    import { useUsersStore } from '@/stores/users';
    import UsersListItem from '@/components/account/users/UsersListItem';
    import ErrorSection from '@/components/sections/ErrorSection';
    import LoadingSection from '@/components/sections/LoadingSection';
    import NoResultsSection from '@/components/sections/NoResultsSection';

    export default {
        name: 'UsersList',
        components: {
            UsersListItem,
            ErrorSection,
            LoadingSection,
            NoResultsSection
        },
        computed: {
            ...mapState(useUsersStore, ['users', 'loading', 'error', 'found', 'options']),
        },
        methods: {
            getUsers() {
                return useUsersStore().fetchUsers();
            }
        },
        created() {
            this.getUsers();
        }
    }
</script>