<template>
    <template v-if="!loading && !error && found > 0">
        <div class="container-fluid item-lists-type-3">
            <div class="container">
                <ProspectsListItem
                    v-for="prospect in displayedProspects"
                    :key="prospect.id"
                    :prospect="prospect"
                />
            </div>
        </div>
        <PaginationPanel
            :pagination="pagination"
            :total="total"
            @update-pagination="handlePaginationUpdate"
        />
    </template>
    <NoResultsSection
        v-else-if="!loading && !error"
        :message="options.search ? 'views.prospects.no_results_search' : ''"
        :translationPlaceholders="options.search ? { query: `<span>${options.search}</span>` } : {}"
    />
    <ErrorSection v-else-if="error" :error="error" />
    <LoadingSection v-else />
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useProspectsStore } from '@/stores/prospects';
    import ProspectsListItem from '@/components/account/prospects/ProspectsListItem';
    import ErrorSection from '@/components/sections/ErrorSection';
    import LoadingSection from '@/components/sections/LoadingSection';
    import NoResultsSection from '@/components/sections/NoResultsSection';
    import PaginationPanel from '@/components/panels/pagination/PaginationPanel';

    export default {
        name: 'ProspectsList',
        components: {
            ProspectsListItem,
            ErrorSection,
            LoadingSection,
            NoResultsSection,
            PaginationPanel
        },
        data() {
            return {
                displayedProspects: []
            };
        },
        computed: {
            ...mapState(useProspectsStore, ['prospects', 'loading', 'error', 'found', 'options', 'pagination', 'total']),
        },
        methods: {
            ...mapActions(useProspectsStore, ['loadProspects', 'updatePagination', 'resetProspects']),
            handlePaginationUpdate(newPagination) {
                this.updatePagination(newPagination);
            }
        },
        created() {
            this.resetProspects();
            this.loadProspects();
        },
        watch: {
            prospects(newProspects) {
                this.displayedProspects = [];
                newProspects.forEach((prospect, index) => {
                    setTimeout(() => {
                        this.displayedProspects.push(prospect);
                    }, index * 50 < 500 ? index * 50 : 500);
                });
            }
        },
    }
</script>