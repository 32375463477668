<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        cssClass="content-type-18"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_apps.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="categories">
                    <div class="category" v-for="(cat, index) in categories" :key="index">
                        <div class="name">{{ $te('components.prospect_apps.categories.' + cat.id) ? $t('components.prospect_apps.categories.' + cat.id) : cat.name }}</div>

                        <div v-if="cat.apps" class="apps">
                            <div class="app" v-for="(app, index) in cat.apps" :key="index">
                                <AppLogo :app="app.id" v-if="app.img" />
                                <span>{{ app.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';
    import AppLogo from '@/components/elements/misc/AppLogo';

    export default {
        name: "ProspectAppsDetails",
        components: {
            WidgetSection,
            AppLogo
        },
        props: {
            defaultToggle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loaded: true
            }
        },
        computed: {
            apps() {
                return useProspectStore().apps;
            },
            categories() {
                return [...this.apps.categories].sort((a, b) => {
                    if (a.id === 'miscellaneous') return 1;
                    if (b.id === 'miscellaneous') return -1;
                    return a.id.localeCompare(b.id);
                });
            },
            hide() {
                return !this.apps?.categories?.length > 0;
            }
        },
        methods: {

        },
    }
</script>