<template>
  <div :class="cssClass">
    <div class="popup-container">
      <div v-if="showCloseIcon" class="close-icon" @click="closePopup">
          <div class="icon-close-pos"></div>
      </div>
      <div class="content">
        <h3>{{ translationPlaceholders && translationPlaceholders.title ? $t(title, translationPlaceholders.title ) : $t(title) }}</h3>
        <p>{{ translationPlaceholders && translationPlaceholders.description ? $t(description, translationPlaceholders.description ) : $t(description) }}</p>
        <button :class="['btn-m', loading ? 'btn-disabled' : danger ? 'btn-6' : 'btn-1']" @click="handleConfirm">{{ $t(btnConfirm) }}<div v-if="loading" class="loading-spinner-type-1 primary"></div></button>
        <button class="btn-m btn-4" @click="closePopup">{{ $t(btnCancel) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { usePopupStore } from '@/stores/popup';
import { mapActions } from 'pinia';

export default {
    name: 'PopupFetch',
    props: {
        title: String,
        description: String,
        fetchFunction: Function,
        btnConfirm: {
            type: String,
            default: 'common.ok_1'
        },
        btnCancel: {
            type: String,
            default: 'common.cancel_1'
        },
        showCloseIcon: {
            type: Boolean,
            default: true
        },
        cssClass: {
            type: String,
            default: 'popup-type-1'
        },
        danger: {
            type: Boolean,
            default: false
        },
        translationPlaceholders: {
            type: Object,
            default: () => ({})
        },
        onFetchSuccess: {
            type: Function,
            required: false
        },
        onFetchError: {
            type: Function,
            required: false
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        ...mapActions(usePopupStore, ['closePopup']),
        async handleConfirm() {
            if (this.fetchFunction) {
                this.loading = true;

                try {
                    const response = await this.fetchFunction();
                    this.closePopup();

                    if(response && this.onFetchSuccess) {
                        this.onFetchSuccess();
                    }
                } catch {
                    if(this.onFetchError) {
                        this.onFetchError();
                    }
                } finally {
                    this.loading = false;
                    // this.closePopup();
                }
            }
        }
    }
};
</script>
