<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @loading-start="handleLoadingStart"
        loadingText="common.loading_1"
        cssClass="content-type-21"
        :paywall="paywall"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_events.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <ul class="events" v-if="events?.length > 0" ref="events">
                    <li v-for="(event, index) in events" :key="event.type + '-' + index" class="event">
                        <div v-if="formatEventType(event.event).icon || $te(formatEventType(event.event).text)" class="type">
                            <div :class="[`icon-${formatEventType(event.event).icon}-pos`]" v-if="formatEventType(event.event).icon"></div>
                            <div class="right" v-if="event.event || event.time">
                                <div v-if="event.event" class="title">
                                    {{$te(formatEventType(event.event).text) ? $t(formatEventType(event.event).text) : event.event}}
                                </div>
                                <div v-if="timestampToDisplayDate(event.time)" class="date">
                                    {{timestampToDisplayDate(event.time)}}
                                </div>
                            </div>
                        </div>
                        
                        <div v-if="event.message" v-html="formatMessage(event.message)" class="description"></div>

                        <div v-if="eventOwner(event)[0].email || eventOwner(event)[0].name" class="owner">
                            <GravatarImage 
                                :email="eventOwner(event)[0].email ? eventOwner(event)[0].email : null"
                                :name="eventOwner(event)[0].name ? eventOwner(event)[0].name : eventOwner(event)[0].email ? eventOwner(event)[0].email : null"
                            />
                            <div class="right">
                                <div class="name">{{eventOwner(event)[0].name || '-'}}</div>
                                <div v-if="eventOwner(event)[0].email" class="email">{{eventOwner(event)[0].email}}</div>
                            </div>
                        </div>
                        <div class="actions">
                            <div v-if="event.editable" class="btn-s-icon btn-6" @click="deleteEventPopup(event)">
                                <div class="icon-trash-neg"></div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="events-form form-type-1">
                    <CustomRadio
                        v-model="type"
                        :inputKey="inputKey"
                        :options="options"
                        :allowEmpty="true"
                        :toggleReset="true"
                        :showLabel="true"
                        cssClass="custom-radio-type-2"
                    />
                    <CustomTextarea
                        v-model="note"
                        inputKey="prospect_note"
                        ref="note"
                        :showLabel="false"
                        v-if="showForm"
                    />
                    <button :class="[note ? 'btn-1' : 'btn-disabled']" v-if="showForm" :disabled="!note" @click="addEvent">{{ $t('common.save_1')}}<span v-if="creatingEvent" class="loading-spinner-type-1"></span></button>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { mapState } from 'pinia';
    import { useProspectStore } from '@/stores/prospect';
    import { useUserStore } from '@/stores/user';
    import { useUsersStore } from '@/stores/users';
    import { useAccountStore } from '@/stores/account';
    import WidgetSection from '@/components/sections/WidgetSection';
    import CustomTextarea from '@/components/elements/inputs/CustomTextarea';
    import CustomRadio from '@/components/elements/inputs/CustomRadio';
    import i18n from "@/plugins/i18n";
    import { usePopupStore } from '@/stores/popup';
    import { useToastStore } from '@/stores/toast';
    import { timestampToDisplayDate } from '@/utils/dates';
    import GravatarImage from '@/components/elements/misc/GravatarImage';

    export default {
        name: "ProspectPeople",
        components: {
            WidgetSection,
            CustomTextarea,
            CustomRadio,
            GravatarImage
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            },
            paywall: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                inputKey: 'prospect_note_type',
                loadingEvents: false,
                creatingEvent: false,
                loaded: false,
                note: '',
                type: '',
                types: [
                    {
                        key: 'phone',
                        icon: 'phone',
                    },
                    {
                        key: 'email',
                        icon: 'email',
                    },
                    {
                        key: 'meeting',
                        icon: 'calendar',
                    },
                    {
                        key: 'note',
                        icon: 'edit',
                    },
                ]
            }
        },
        computed: {
            ...mapState(useProspectStore, ['events']),
            ...mapState(useUsersStore, ['users', 'loadingUsers', 'getUsers']),
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            },
            hide() {
                return false;
            },
            user() {
                return useUserStore().user;
            },
            options() {
                return this.types.map(type => ({
                    value: type.key,
                    text: 'components.prospect_events.types.' + type.key
                }))
            },
            showForm() {
                return this.type !== '';
            },
            loading() {
                return this.loadingEvents || this.loadingUsers;
            }
        },
        methods: {
            timestampToDisplayDate,
            formatMessage(text) {
                return text.replace(/\n/g, '<br>');
            },
            formatEventType(type) {
                if(type.includes('user-')) {
                    type = type.replace('user-','');
                }
                const types = Object.values(this.types).filter(typesItem => typesItem.key === type);

                if(types?.length > 0) {
                    return {
                        icon: types[0].icon,
                        text: 'components.prospect_events.types.' + types[0].key
                    }
                } else {
                    return false;
                }
            },
            eventOwner(event) {
                if(this.users?.length > 0) {
                    return Object.values(this.users).filter(user => user.uid === event.owner_id);
                } else if (event.owner_id) {
                    return { id: event.owner_id };
                } else {
                    return false;
                }
            },
            scrollToBottom() {
                this.$nextTick(() => {
                    const list = this.$refs.events;
                    if (list) {
                        list.scrollTop = list.scrollHeight;
                    }
                });
            },
            async fetchEvents() {
                this.loadingEvents = true;
                await useProspectStore().fetchEvents();
                this.loadingEvents = false;
                this.loaded = true;
                this.scrollToBottom();
            },
            async addEvent() {
                this.creatingEvent = true;

                if(this.type && this.note) {
                    try {
                        const response = await useProspectStore().addEvent({
                            event: 'user-' + this.type,
                            message: this.note
                        });

                        if (response) {
                            this.note = '';
                            this.type = '';
                            // Confirm success
                            useToastStore().addToast(i18n.global.t('toasts.success.event_added'), 'success');
                            this.scrollToBottom();
                        } else {
                            useToastStore().addToast(i18n.global.t('toasts.error.event_not_added'), 'error');
                        }
                    } catch {
                        useToastStore().addToast(i18n.global.t('toasts.error.event_not_added'), 'error');
                    }
                }

                this.creatingEvent = false;
            },
            async updateEvent(event) {
                if(event.editable) {
                    try {
                        const response = await useProspectStore().editEvent(event);

                        if (response) {
                            useToastStore().addToast(i18n.global.t('toasts.success.event_updated'), 'success');
                        } else {
                            useToastStore().addToast(i18n.global.t('toasts.error.event_not_updated'), 'error');
                        }
                    } catch {
                        // Something went wrong
                        useToastStore().addToast(i18n.global.t('toasts.error.event_not_updated'), 'error');
                    }
                } else {
                    // No rights
                }
            },
            deleteEventPopup(event) {
                usePopupStore().showPopup('confirm', {
                    title: 'popups.confirm_delete_event.title',
                    description: 'popups.confirm_delete_event.description',
                    btnConfirm: "popups.confirm_delete_event.button_confirm",
                    btnCancel: "common.cancel_1",
                    confirmFunction: () => this.deleteEvent(event),
                    danger: true
                });
            },
            async deleteEvent(event) {
                if(event.editable) {
                    try {
                        const response = await useProspectStore().deleteEvent({ event_id: event.event_id });

                        if (response) {
                            useToastStore().addToast(i18n.global.t('toasts.success.event_deleted'), 'success');
                        } else {
                            useToastStore().addToast(i18n.global.t('toasts.error.event_not_deleted'), 'error');
                        }
                    } catch {
                        // Something went wrong
                        useToastStore().addToast(i18n.global.t('toasts.error.event_not_deleted'), 'error');
                    }
                } else {
                    // No rights
                }
            },
            async handleLoadingStart() {
                if (!this.paywallActive && !this.loaded) {
                    await this.fetchEvents();

                    if(!this.hide) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                }
            },
        },
        async mounted() {
            if(!this.paywallActive && !this.loaded && (this.auto || this.defaultToggle)) {
                await this.getUsers();
                await this.fetchEvents();
            }
        }
    }
</script>