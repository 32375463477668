<template>
    <div class="section fundamentals">
        <ul class="specs top" v-if="showMiddle">
            <li v-if="data.products_services">
                <div class="title">{{ $t('components.prospect_ai.fields.products_services') }}</div>
                <div class="content">
                    <ul v-if="Array.isArray(data.products_services) && data.products_services.length > 0" class="list">
                        <li v-for="(item, index) in data.products_services" :key="index">
                            <span>{{ item }}</span>
                        </li>
                    </ul>
                    <template v-else>{{data.products_services}}</template>
                </div>
            </li>
            <li v-if="data.target_audience_segments">
                <div class="title">{{ $t('components.prospect_ai.fields.target_audience_segments') }}</div>
                <div class="content">
                    <ul v-if="Array.isArray(data.target_audience_segments) && data.target_audience_segments.length > 0" class="list">
                        <li v-for="(item, index) in data.target_audience_segments" :key="index">
                            <span>{{ item }}</span>
                        </li>
                    </ul>
                    <template v-else>{{data.target_audience_segments}}</template>
                </div>
            </li>
            <li v-if="data.revenue_streams">
                <div class="title">{{ $t('components.prospect_ai.fields.revenue_streams') }}</div>
                <div class="content">
                    <ul v-if="Array.isArray(data.revenue_streams) && data.revenue_streams.length > 0" class="list">
                        <li v-for="(item, index) in data.revenue_streams" :key="index">
                            <span>{{ item }}</span>
                        </li>
                    </ul>
                    <template v-else>{{data.revenue_streams}}</template>
                </div>
            </li>
            <li v-if="data.cost_centers">
                <div class="title">{{ $t('components.prospect_ai.fields.cost_centers') }}</div>
                <div class="content">
                    <ul v-if="Array.isArray(data.cost_centers) && data.cost_centers.length > 0" class="list">
                        <li v-for="(item, index) in data.cost_centers" :key="index">
                            <span>{{ item }}</span>
                        </li>
                    </ul>
                    <template v-else>{{data.cost_centers}}</template>
                </div>
            </li>
        </ul>

        <ul class="specs middle" v-if="showTop">
            <li v-if="data.expertise">
                <div class="title">{{ $t('components.prospect_ai.fields.expertise') }}</div>
                <div class="content">
                    {{data.expertise[0]}}
                </div>
            </li>
            <li v-if="data.unique_trait">
                <div class="title">{{ $t('components.prospect_ai.fields.unique_trait') }}</div>
                <div class="content">
                    {{data.unique_trait[0]}}
                </div>
            </li>
        </ul>

        <ul class="specs bottom" v-if="showBottom">
            <li v-if="data.market_conditions">
                <div class="title">{{ $t('components.prospect_ai.fields.market_conditions') }}</div>
                <div class="content">
                    {{data.market_conditions[0]}}
                </div>
            </li>
            <li v-if="data.challenges">
                <div class="title">{{ $t('components.prospect_ai.fields.challenges') }}</div>
                <div class="content">
                    {{data.challenges[0]}}
                </div>
            </li>
            <li v-if="data.opportunities">
                <div class="title">{{ $t('components.prospect_ai.fields.opportunities') }}</div>
                <div class="content">
                    {{data.opportunities[0]}}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "ProspectAiFundamentals",
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        computed: {
            showTop() {
                return this.data?.expertise?.[0] && this.data?.unique_trait?.[0];
            },
            showMiddle() {
                return (this.data?.products_services &&
                Array.isArray(this.data.products_services) &&
                this.data.products_services.length > 0 &&
                this.data?.target_audience_segments &&
                Array.isArray(this.data.target_audience_segments) &&
                this.data.target_audience_segments.length > 0) ||
                (this.data?.revenue_streams &&
                Array.isArray(this.data.revenue_streams) &&
                this.data.revenue_streams.length > 0 &&
                this.data?.revenue_streams &&
                Array.isArray(this.data.revenue_streams) &&
                this.data.revenue_streams.length > 0);
            },
            showBottom() {
                return this.data?.market_conditions?.[0] && this.data?.challenges?.[0] && this.data?.opportunities?.[0];
            },
        },
        methods: {
            formatKey(key) {
                return key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
            }
        }
    };
</script>