<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        v-if="!hide"
        :defaultToggle="defaultToggle"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_contact_information.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">  
                <div class="section">
                    <ul v-if="company" class="specs contact-information">
                        <li v-if="company.url">
                            <div class="title">
                                {{ $t('common.website')}}
                            </div>
                            <div class="content">
                                <a v-if="company.url" :href="company.url" target="_blank" rel="noreferrer external">
                                    {{ company.url }}
                                </a>
                            </div>
                        </li>
                        <li v-if="company.phone_international">
                            <div class="title">
                                {{ $t('common.phone')}}
                            </div>
                            <div class="content">
                                <a :href="'tel:' + company.phone_international.replace('+', '00').replaceAll(' ', '')" target="_blank" rel="external">
                                    {{ company.phone_international }}
                                </a> 
                            </div>
                        </li>
                        <li v-if="company.email">
                            <div class="title">
                                {{ $t('common.email')}}
                            </div>
                            <div class="content">
                                <a :href="'mailto:' + company.email" target="_blank" rel="external">
                                    {{ company.email }}
                                </a> 
                            </div>
                        </li>
                        <li v-if="company.address">
                            <div class="title">
                                {{ $t('common.address')}}
                            </div>
                            <div class="content">
                                {{ company.address }}
                            </div>
                            <div class="content" v-if="company.postcode || company.city">
                                {{ company.postcode }}
                                {{ company.city }}
                            </div>
                        </li>
                    </ul>   

                    <div v-if="hasLinks" class="links">
                        <a v-if="location.google_route" :href="location.google_route" class="link" target="_blank" rel="noreferrer external">{{ $t('common.google_maps')}}</a>
                        <a v-if="location.google_streetview" :href="location.google_streetview" class="link" target="_blank" rel="noreferrer external">{{ $t('common.google_streetview')}}</a>
                        <a v-if="location.bing_route" :href="location.bing_route" class="link" target="_blank" rel="noreferrer external">{{ $t('common.bing_maps')}}</a>
                    </div>
                    <div class="buttons" v-if="hasSocials">
                        <template v-for="social in socials" :key="social">
                            <a v-if="company?.[social + '_link']" :href="company[social + '_link']" class="btn-1 btn-s-icon" target="_blank" rel="noreferrer noopener">
                                <div :class="`icon-${social !== 'twitter' ? social : 'x'}-pos`"></div>
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';

    export default {
        name: "ProspectContactInfo",
        components: {
            WidgetSection
        },
        props: {
            defaultToggle: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                loaded: true,
                socials: ['linkedin', 'facebook', 'twitter', 'instagram', 'youtube', 'pinterest']
            }
        },
        computed: {
            company() {
                return useProspectStore().prospect;
            },
            location() {
                return useProspectStore().location;
            },
            hasLinks() {
                return this.location && (
                this.location.google_route ||
                this.location.google_streetview ||
                this.location.bing_route );
            },
            hasSocials() {
                return !this.company || (
                this.company.linkedin_link ||
                this.company.facebook_link ||
                this.company.twitter_link ||
                this.company.instagram_link ||
                this.company.youtube_link ||
                this.company.pinterest_link );
            },
            hide() {
                return !this.company && !this.hasLinks && !this.hasSocials;
            }
        }
    }
</script>