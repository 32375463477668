import { useUserStore } from '@/stores/user';
import { useAccountStore } from '@/stores/account';

export function ifNotAuthenticatedRedirect(to, from, next) {
  const userStore = useUserStore();

  if (!userStore.isAuthenticated) {
    userStore.setRedirectAfterLogin(to.fullPath); 
    next({ name: 'login' });
  } else {
    next();
  }
}

export function ifAuthenticatedRedirect(to, from, next) {
    const userStore = useUserStore();
  
    if (userStore.isAuthenticated) {
      next({ name: 'home' });
    } else {
      next();
    }
}

export function hasQueryParamPasswordSelect(to, from, next) {
    if (!to.query.r) {
      next({ name: 'passwordReset' });
    } else {
      next();
    }
}

export async function hasAccountId(to, from, next) {
    const accountStore = useAccountStore();
    const accountId = to.query.account_id;
  
    if (accountId) {
      accountStore.changeAccount(accountId);
    }

    if (accountStore.hasAccountId) {
        next();
    } else {
        next({ name: 'accounts' });
    }
}

export async function hasAccountAdminRights(to, from, next) {
    const userStore = useUserStore();

    if(userStore.isPartnerAdmin) {
        next();
    } else {
        const accountStore = useAccountStore();
        const account = await accountStore.getAccount();

        if(account?.role < 15) {
            next();
        } else {
            next({ name: 'prospects' });
        }
    }
}

export async function isActiveAccount(to, from, next) {
    const userStore = useUserStore();

    if(userStore.isPartnerAdmin) {
        next();
    } else {
        const accountStore = useAccountStore();
        const account = await accountStore.getAccount();

        if(account?.status > 0) {
            next();
        } else {
            next({ name: 'prospects' });
        }
    }
}