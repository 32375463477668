<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        v-if="!hide"
        :defaultToggle="defaultToggle"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_firmology.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="section">
                    <ul v-if="company" class="specs firmology">
                        <li v-if="company.industry_primary">
                            <div class="title">
                                {{ $t('common.industry')}}
                            </div>
                            <div class="content">
                                {{ company.industry_primary }}
                            </div>
                        </li>
                        <li v-if="company.founded && company.founded != '-1'">
                            <div class="title">
                                {{ $t('common.founded')}}
                            </div>
                            <div class="content">
                                {{ company.founded }}
                            </div>
                        </li>
                        <li v-if="company.employees_range_text">
                            <div class="title">
                                {{ $t('common.employees_range')}}
                            </div>
                            <div class="content">
                                {{ formatTextRange(company.employees_range_text) }}
                            </div>
                        </li>
                        <li v-if="company.revenue_range_text">
                            <div class="title">
                                {{ $t('common.revenue_range')}}
                            </div>
                            <div class="content">
                                {{ formatTextRange(company.revenue_range_text, '€', 'm') }}
                            </div>
                        </li>
                    </ul>
                    <ul v-if="company" class="specs firmology">
                        <li v-if="company.orgtype">
                            <div class="title">
                                {{ $t('common.orgtype')}}
                            </div>
                            <div class="content">
                                {{ company.orgtype }}
                            </div>
                        </li>
                        <li v-if="company.coc">
                            <div class="title">
                                {{ $t('common.coc')}}
                            </div>
                            <div class="content">
                                {{ company.coc }}
                            </div>
                        </li>
                        <li v-if="company.vat">
                            <div class="title">
                                {{ $t('common.vat')}}
                            </div>
                            <div class="content">
                                {{ company.vat }}
                                <div v-if="vatStatus !== null" :class="['indicator-type-1', vatStatus === 1 ? 'valid' : vatStatus === 0 ? 'invalid' : '']"></div>
                            </div>
                        </li>
                    </ul>       
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';
    import { fetchUrl } from '@/services/ppApi';
    import { formatTextRange } from '@/utils/formatting';

    export default {
        name: "ProspectContactInformation",
        components: {
            WidgetSection
        },
        props: {
            defaultToggle: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                loaded: true,
                vatStatus: null
            }
        },
        computed: {
            company() {
                return useProspectStore().prospect;
            },
            vat() {
                return useProspectStore().vat;
            },
            showVatVerification() {
                return !!this.vat && !!Object.keys(this.vat).length > 0 && this.vat.api_urls?.length > 0;
            },
            hide() {
                return !this.company && !this.vat
            }
        },
        methods: {
            formatTextRange,
            async verifyVat() {
                if (!this.vat || this.vat.api_urls?.length < 1) { return; }
                try {
                    const response = await fetchUrl(this.vat.api_urls[0]);
                    if(response.data.vat_detail?.active === 1) {
                        this.vatStatus = response.data.vat_detail.active;
                    } else if (response.data.vat_detail && Object.keys(response.data.vat_detail).length < 1) {
                        this.vatStatus = 0;
                    }
                } catch {
                    this.vatStatus = null;
                }
            }
        },
        mounted() {
            this.verifyVat();
        }
    }
</script>