<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        v-if="!hide"
        :defaultToggle="defaultToggle"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_ratings.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">  
                <div v-if="sources?.length > 0" class="reviews-type-1">
                    <div class="section-total">
                        <div class="title">
                            {{ $t('components.prospect_ratings.total')}}
                        </div>
                        <StarsReview v-if="combinedSources.rating" :score="combinedSources.rating" :total="combinedSources.reviews" />
                    </div>

                    <div class="section-sources">
                        <div class="title">
                            {{ $t('components.prospect_ratings.sources')}}
                        </div>
                        <ul class="sources">
                            <li v-for="source in sources" :key="source.id" class="source" :class="[source.url ? 'has-link' : '']">
                                <template v-if="source.url">
                                    <a :href="source.url" target="_blank" rel="noreferrer">
                                        <CompanyLogo
                                            :domain="source.domain ? source.domain : null"
                                            :company_name="source.name ? source.name : source.domain ? source.domain : null"
                                        />
                                        <StarsReview :score="source.rating" :total="source.reviews" :showStars="false" />
                                    </a>
                                    </template>
                                    <template v-else>
                                        <CompanyLogo
                                            :domain="source.domain ? source.domain : null"
                                            :company_name="source.name ? source.name : source.domain ? source.domain : null"
                                        />
                                        <StarsReview :score="source.rating" :total="source.reviews" :showStars="false" />
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>          
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';
    import StarsReview from '@/components/elements/misc/StarsReview';

    export default {
        name: "ProspectReviewsSummary",
        components: {
            CompanyLogo,
            StarsReview,
            WidgetSection
        },
        props: {
            defaultToggle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loaded: true
            }
        },
        computed: {
            sources() {
                return useProspectStore().reviewsSummary;
            },
            combinedSources() {
                if (!this.sources.length) return { totalReviews: 0, weightedRating: 0 };

                let totalReviews = 0;
                let weightedSum = 0;

                this.sources.forEach(({ rating, reviews }) => {
                    const reviewCount = parseInt(reviews, 10) || 1;
                    totalReviews += reviewCount;
                    weightedSum += parseFloat(rating) * reviewCount;
                });

                const weightedRating = totalReviews ? (weightedSum / totalReviews).toFixed(1) : 0;

                return {
                    rating: weightedRating,
                    reviews: totalReviews
                };
            },
            hide() {
                return !this.sources || this.sources.length < 1;
            }
        }
    }
</script>