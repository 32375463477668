<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <SuspectOptions v-if="showSuspectOptions && !suspectLoading" />
        
        <div @click="toggleOptions(false)" v-if="showSuspectOptions && !suspectLoading" class="options-escape-type-1"></div>

        <div v-if="suspectLoading" class="loading-section-type-2">
            <div class="loading-spinner"></div>
            <div class="loading-text">{{ $t('common.loading_1') }}</div>
        </div>

        <SuspectDetails v-else-if="prospect" />
        <SuspectActions @toggle-options="toggleOptions()" :showSuspectOptions="showSuspectOptions" />
    </AccountViewBase>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import { useSuspectStore } from '@/stores/suspect';
    import { useProspectStore } from '@/stores/prospect';
    import SuspectDetails from '@/components/account/suspect/SuspectDetails';
    import SuspectOptions from '@/components/account/suspect/SuspectOptions';
    import SuspectActions from '@/components/account/suspect/SuspectActions';

    export default {
        name: 'SuspectView',
        components: {
            AccountViewBase,
            SuspectDetails,
            SuspectOptions,
            SuspectActions
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.suspects.seo_title',
                showSuspectOptions: false
            };
        },
        computed: {
            ...mapState(useSuspectStore, { suspectLoading: 'loading', suspectError: 'error' }),
            ...mapState(useProspectStore, { prospect: "prospect" }),
            error() {
                return this.accountError || this.suspectError;
            }
        },
        methods: {
            ...mapActions(useSuspectStore, ['fetchFirstSuspect', 'resetSuspect']),
            ...mapActions(useProspectStore, ['resetProspect']),
            toggleOptions(show = null, event = null) {
                if (event) {
                    event.stopPropagation(); // Prevent timing issues!
                }

                this.showSuspectOptions = !this.loading && !this.prospect ? true : show !== null ? show : !this.showSuspectOptions;
            }
        },
        async mounted() {
            this.resetProspect();
            this.resetSuspect(); // Ensure appropriate userSuspectSettings are loaded
            await this.fetchFirstSuspect();

            if(this.prospect === null) {
                this.toggleOptions(true);
            }
        },
        unmounted() {
            this.resetProspect();
            this.resetSuspect();
        },
        watch: {
            prospect(val) {
                if(val) {
                    this.toggleOptions(false);
                } else if (val === null) {
                    this.toggleOptions(true);
                }
            }
        },
    }
</script>
