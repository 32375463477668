<template>
  <div :class="cssClass">
    <div class="popup-container popup-confirm">
      <div v-if="showCloseIcon" class="close-icon" @click="closePopup">
          <div class="icon-close-pos"></div>
      </div>
      <div class="content">
        <h3>{{ translationPlaceholders && translationPlaceholders.title ? $t(title, translationPlaceholders.title ) : $t(title) }}</h3>
        <p>{{ translationPlaceholders && translationPlaceholders.description ? $t(description, translationPlaceholders.description ) : $t(description) }}</p>
        <button :class="['btn-m', danger ? 'btn-6' : success ? 'btn-7' : 'btn-1']" @click="handleConfirm">{{ $t(btnConfirm) }}</button>
        <button class="btn-m btn-4" v-if="showBtnCancel" @click="closePopup">{{ $t(btnCancel) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { usePopupStore } from '@/stores/popup';
import { mapActions } from 'pinia';

export default {
    name: 'PopupConfirm',
    props: {
        title: String,
        description: String,
        confirmFunction: Function,
        btnConfirm: {
            type: String,
            default: 'common.ok_1'
        },
        btnCancel: {
            type: String,
            default: 'common.cancel_1'
        },
        showBtnCancel: {
            type: Boolean,
            default: true
        },
        showCloseIcon: {
            type: Boolean,
            default: true
        },
        cssClass: {
            type: String,
            default: 'popup-type-1'
        },
        success: {
            type: Boolean,
            default: false
        },
        danger: {
            type: Boolean,
            default: false
        },
        translationPlaceholders: {
            type: Object,
            default: () => ({})
        },
    },
    methods: {
        ...mapActions(usePopupStore, ['closePopup']),
        handleConfirm() {
            if (this.confirmFunction) {
                this.confirmFunction();
            }
            this.closePopup();
        }
    }
};
</script>
