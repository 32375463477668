<template>
    <OptionsPanelContent ref="optionsPanelContent"
        v-if="showOptions"
        :hasLargeScreen="hasLargeScreen"
        :cssClass="cssClass"
        @toggle-close="toggleOptions(false)"
    >
        <template v-if="$slots['options-results']" v-slot:options-results>
            <slot name="options-results"></slot>
        </template>

        <template v-slot:options-default>
            <slot name="options-default"></slot>
        </template>

        <template v-if="hasOptionsFull" v-slot:options-full>
            <slot name="options-full" v-if="hasOptionsFull"></slot>
        </template>
        
        <template v-if="$slots['actions']" v-slot:actions>
            <slot name="actions"></slot>
        </template>
    </OptionsPanelContent>
    <OptionsPanelButton v-if="!showOptions" :cssClass="cssClass" @click="toggleOptions(true, $event)"/>
</template>

<script>
    import { useScreenWidthStore } from '@/stores/utils/screenWidth';
    import OptionsPanelContent from '@/components/panels/options/OptionsPanelContent';
    import OptionsPanelButton from '@/components/panels/options/OptionsPanelButton';

    export default {
        name: "OptionsPanel",
        components: {
            OptionsPanelContent,
            OptionsPanelButton
        },
        props: {
            cssClass: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                optionsExpanded: false
            };
        },
        computed: {
            screenWidthStore() {
                return useScreenWidthStore();
            },
            hasLargeScreen() {
                return this.screenWidthStore.width >= 992;
            },
            showOptions() {
                return this.optionsExpanded || this.hasLargeScreen;
            },
            hasOptionsFull() {
                return !!this.$slots['options-full'];
            }
        },
        methods: {
            toggleOptions(show = null, event = null) {
                if (event) {
                    event.stopPropagation(); // Prevent timing issues!
                }
                this.optionsExpanded = show !== null ? show : !this.optionsExpanded;
            },
            toggleFullOptions(show = null) {
                this.$refs.optionsPanelContent.toggleFullOptions(show);
            }
        },
        watch: {
            $route() {
                this.toggleOptions(false);
            },
            hasLargeScreen() {
                this.toggleOptions(false);
            }
        },
        beforeUnmount() {
            this.toggleOptions(false);
        }
    }
</script>