<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @toggle="handleToggle"
        cssClass="slider-type-1"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_reviews.name')}}
            </div>
        </template>

        <template v-slot:section-actions>
            <div class="swiper-navigation">
                <div :class="`swiper-button-prev-${uniqueId}`"></div>
                <div :class="`swiper-button-next-${uniqueId}`"></div>
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-slider">
                <swiper-container ref="swiper" init="false">
                    <swiper-slide v-for="(review, index) in reviews" :key="index">
                        <component :is="review.url ? 'a' : 'div'" :href="review.url || undefined" target="_blank" rel="noreferrer external" class="slide-container">
                            <StarsReview v-if="review.rating" :score="review.rating" :fullWidth="true" />
                            <div v-if="review.date" class="review-date">{{formatDateReverse(review.date)}}</div>
                            <div class="review-content">
                                <div class="description">
                                    {{ review.text }}
                                </div>
                            </div>
                            <div class="bar">
                                <div class="left">
                                    <ContactImage 
                                        :imgUrl="review.user_img"
                                        :name_first="review.name"
                                    />
                                </div>
                                <div class="right">
                                    <div class="name-review" v-if="review.name">{{ review.name }}</div>
                                    <div class="source" v-if="review.source">{{$t('common.source')}}: {{ review.source }}</div>
                                </div>
                            </div>
                        </component>
                    </swiper-slide>
                </swiper-container>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';
    import ContactImage from "@/components/elements/misc/ContactImage";
    import StarsReview from '@/components/elements/misc/StarsReview';
    import { formatDateReverse } from '@/utils/formatting';

    export default {
        name: "ProspectApps",
        components: {
            WidgetSection,
            ContactImage,
            StarsReview
        },
        props: {
            defaultToggle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loaded: true
            }
        },
        computed: {
            reviews() {
                return useProspectStore().reviewsDetails;
            },
            hide() {
                return !this.reviews || this.reviews.length < 1;
            },
            uniqueId() {
                return Math.random().toString(36).substr(2, 9);
            },
        },
        methods: {
            formatDateReverse,
            initSwiper() {
                if(!this.$refs.swiper) { return; }
                
                const swiperEl = this.$refs.swiper;
                const swiperParams = {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    breakpoints: {
                        769: {
                            slidesPerView: 2,
                            slidesPerGroup: 2
                        },
                        992: {
                            slidesPerView: 3,
                            slidesPerGroup: 3
                        }
                    },
                    navigation: {
                        nextEl: `.swiper-button-next-${this.uniqueId}`,
                        prevEl: `.swiper-button-prev-${this.uniqueId}`
                    }
                };
                Object.assign(swiperEl, swiperParams);
                swiperEl.initialize();
            },
            handleToggle(isVisible) {
                if (isVisible) {
                    this.$nextTick(() => {
                        this.initSwiper(); // Re-initialize Swiper after the DOM updates
                    });
                }
            }
        },
        mounted() {
            if(this.defaultToggle) {
                this.initSwiper();
            }
        }
    }
</script>