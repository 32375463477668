<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <div class="container-fluid content-type-19">
            <div class="container">
                <div class="row row-search">
                    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                        <div class="content">
                            <h1 class="h1">{{ $t('views.company_search.title') }}</h1>
                            <p class="description">{{ $t('views.company_search.description') }}</p>
                            <SearchBasic />
                            <SearchExamples />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AccountViewBase>
</template>

<script>
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import SearchBasic from '@/components/elements/search/SearchBasic';
    import SearchExamples from '@/components/elements/search/SearchExamples';

    export default {
        name: 'CompanySearchView',
        components: {
            AccountViewBase,
            SearchBasic,
            SearchExamples
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.company_search.seo_title'
            };
        }
    }
</script>
