import { defineStore } from 'pinia';
import { useAccountStore } from '@/stores/account';
import { fetchUsers, createUser, updateUser, deleteUser } from "@/services/ppApi";

export const useUsersStore = defineStore('users', {
    state: () => {
        const defaultOptions = {
            search: '',
            sort: 'default',
            label: 'all'
        };
        return {
            users: [],
            updatingOptions: false,
            error: null,
            loading: false,
            found: 0,
            total: 0,
            options: { ...defaultOptions },
            defaultOptions: defaultOptions,
        }
    },
    getters: {
        hasDefaultOptions(state) {
            return JSON.stringify(state.options) === JSON.stringify(state.defaultOptions);
        }
    },
    actions: {
        async fetchUsers() {
            this.loading = true;
            this.error = null;

            try {
                const params = this.generateOptionParams();
                const response = await fetchUsers(params);
                this.users = response.data.users;
                this.found = response.data.found;
                this.total = this.found === 0 ? 0 : response.data.total || this.total;
            } catch (error) {
                this.users = [];
                this.found = 0;
                this.total = 0;
                this.error = error;
            } finally {
                this.loading = false;
                this.updatingOptions = false;
            }
        },
        generateOptionParams() {
            const accountStore = useAccountStore();
            const accountId = accountStore.account.account_id;

            return {
                ...(accountId && { account_id: accountId}),
                ...(this.options.search && { text: this.options.search }),
                ...(this.options.sort && !this.options.sort.includes('-') && !this.options.sort.includes('default') && { sort_by: this.options.sort }),
                ...(this.options.sort && this.options.sort.includes('-') && { sort_by: this.options.sort.split('-')[0] }),
                ...(this.options.sort && this.options.sort.includes('-') && { sort_order: this.options.sort.split('-')[1] }),
                ...(this.options.label && !this.options.label != 'all' && { label: this.options.label }),
            };
        },
        updateOptions(newOptions) {
            this.options = { ...this.options, ...newOptions };
            this.fetchUsers();
        },
        setUpdatingOptions(updating) {
            this.updatingOptions = updating;
        },
        resetOptions() {
            this.options = { ...this.defaultOptions };
            this.fetchUsers();
        },
        async getUsers() {
            if (!this.users || this.users.length < 1) {
                await this.fetchUsers();
            }
            return this.users ? this.users : false;
        },
        async createUser(user) {
            try {
                const response = await createUser(user);
                const updatedUser = response.data.users[0];
                this.users.unshift(updatedUser);
                return true;
            } catch (error) {
                return false;
            }
        },
        async updateUser(user) {
            try {
                const response = await updateUser(user);
                const updatedUser = response.data.users[0];
        
                const index = this.users.findIndex(u => u.uid === updatedUser.uid);
                if (index !== -1) {
                    this.users[index] = { ...this.users[index], ...updatedUser };
                } else {
                    this.users.push(updatedUser);
                }
                return true;
            } catch (error) {
                return false;
            }
        },
        async deleteUser(user) {
            try {
                await deleteUser(user);
                this.users = this.users.filter(existingUser => existingUser.uid !== user.uid);
                return true;
            } catch (error) {
              return false;
            }
        },
    }
});
