<template>
    <MainNav />
    <PopupContainer />
    <ViewBackground />
    <ToastNotifications />
    <router-view/>
</template>

<script>
    import { throttle } from 'lodash';
    import MainNav from '@/components/navigation/MainNav';
    import PopupContainer from '@/components/popups/PopupContainer';
    import ViewBackground from '@/components/sections/ViewBackground';
    import ToastNotifications from '@/components/elements/misc/ToastNotifications';
    import { useScreenWidthStore } from '@/stores/utils/screenWidth';

    export default {
        components: {
            MainNav,
            PopupContainer,
            ViewBackground,
            ToastNotifications
        },
        data() {
            return {
                screenWidthStore: useScreenWidthStore()
            };
        },
        methods: {
            updateWidth() {
                this.screenWidthStore.updateWidth();
            },
            throttledUpdateWidth: throttle(function() {
                this.updateWidth();
            }, 200)
        },
        mounted() {
            this.updateWidth();
            window.addEventListener('resize', this.throttledUpdateWidth);
        },
        beforeUnmount() {
            window.removeEventListener('resize', this.throttledUpdateWidth);
        }
    };
</script>